// dependencies
import React from 'react';
// ant design & icons
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCogs } from '@fortawesome/pro-light-svg-icons';
// constants
import { emailColumns } from 'src/ui/modules/Storage/constants/email.constant';
// utils
import { server, basePath } from 'src/utils/api-prefix.helper';
import _ from 'lodash';

const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceData: false,
	iconName: faEnvelope,
	url: `${baseApiUrl}email`,
	urlParams: 'order=[["received_at", "DESC"]]',
	splitLaunch: true,
	split: 5000,
	path: '/emails',
	pageTitle: {
		list: 'Emails',
		help: 'Any email sent to bot+{ORGANIZATION_ID}@data.dnext.io will appear here. The size of the email can not exceed 25MB',
		action: {
			create: 'New email',
			edit: 'Edit email',
		},
	},
	columns: emailColumns,
	actions: [
		<Button
			onClick={function () {
				this.props.history.push('/email-ruleset');
			}}
			key="query"
			type="primary"
		>
			<FontAwesomeIcon icon={faCogs} transform="left-3" /> Email ruleset
		</Button>,
	],
};
