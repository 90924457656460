import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Table, Empty, Button, Timeline, Space, Row, Col, Popconfirm } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import DataService from 'utils/DataService';

import moment from 'moment';

import config from 'config';

const { ingestWorkflowUrl } = config.api;

const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={faBars} style={{ cursor: 'pointer', color: '#999' }} />);
const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export class BulkExecutionWotoEmail extends Component {
	constructor(props) {
		super(props);

		this.initialValues = this.props.value;
		this.formFieldName = this.props.name;

		this.state = {
			dataSource: this.props.records?.map((item, index) => ({
				...item,
				key: index,
				index,
			})),
			loading: false,
		};

		this.columns = [
			{
				title: '',
				dataIndex: 'sort',
				width: 30,
				className: 'drag-visible',
				render: () => <DragHandle />,
			},

			{
				title: 'From',
				name: 'from',
				dataIndex: 'from',
				minWidth: 120,
				key: 'from',
				render: (value) => {
					if (!value) return <small>no value</small>;
					return value;
				},
				sorter: (a, b) => (a.from || '').localeCompare(b.from),
			},
			{
				title: 'Subject',
				name: 'subject',
				dataIndex: 'subject',
				minWidth: 120,
				key: 'subject',
				render: (value) => {
					if (!value) return <small>no value</small>;
					return value;
				},
				sorter: (a, b) => (a.subject || '').localeCompare(b.subject),
			},
			{
				title: 'Received',
				name: 'received_at',
				dataIndex: 'received_at',
				minWidth: 120,
				key: 'received_at',
				className: ['drag-visible', 'visible-name'],
				render: (value) => {
					if (!value) return <small>no value</small>;
					return <span>{moment(value).format('LLL')} </span>;
				},
				sorter: (a, b) => moment(a.received_at).unix() - moment(b.received_at).unix(),
			},
		];
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		// console.log("onSortEnd", { oldIndex, newIndex });

		if (oldIndex !== newIndex) {
			this.setState({ dataSource: arrayMove(this.state.dataSource, oldIndex, newIndex) });
		}
	};

	DraggableBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = this.state.dataSource?.findIndex((x) => x.key === restProps['data-row-key']);
		return <SortableItem index={index} {...restProps} />;
	};

	DraggableContainer = (props) => (
		<SortableContainer
			useDragHandle
			helperClass="row-dragging"
			onSortEnd={this.onSortEnd}
			lockAxis="y"
			{...props}
		/>
	);

	execute() {
		console.log('this.state.dataSource', this.state.dataSource);

		this.props.onEndExecution(this.state.dataSource);
	}

	render() {
		const { dataSource, loading } = this.state;
		const { records, onCancel } = this.props;

		if (!dataSource.length) {
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
		}
		return (
			<div>
				<Row>
					<Col flex="30px">
						<Timeline>
							{[...Array(dataSource.length)].map((_item, index) => (
								<Timeline.Item
									key={`timeline-step-${index}`}
									dot={
										<span
											style={{
												display: 'block',
												width: '25px',
												height: '25px',
												border: '1px solid #1890ff',
												borderRadius: '50%',
												padding: '5px 0',
											}}
										>
											{index + 1}
										</span>
									}
									style={{
										height: index === dataSource.length - 1 ? 0 : index ? '40px' : '40px',
										marginTop: index ? 0 : '50px',
									}}
								/>
							))}
						</Timeline>
					</Col>
					<Col flex="auto">
						<Table
							className="form_item_table"
							columns={this.columns}
							dataSource={dataSource}
							showHeader
							pagination={false}
							components={{
								body: {
									wrapper: this.DraggableContainer,
									row: this.DraggableBodyRow,
								},
							}}
							size="small"
							onChange={(a, b, c, datasource) => {
								this.setState({
									dataSource: datasource.currentDataSource,
								});
							}}
						/>
					</Col>
				</Row>
				{/* <Space align="start" style={{ width: "100%" }}>
						
					</Space> */}

				<div className="mt-4" style={{ textAlign: 'right' }}>
					<Button onClick={onCancel} className="mr-3">
						Cancel
					</Button>
					<Popconfirm title="Are you sure?" onConfirm={() => this.execute()}>
						<Button type="primary" loading={loading}>
							Execute
						</Button>
					</Popconfirm>
				</div>
			</div>
		);
	}
}
BulkExecutionWotoEmail.propTypes = {
	records: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withTranslation()(BulkExecutionWotoEmail);
