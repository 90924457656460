import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OneColumnPage from 'ui/layout/OneColumnPage/OneColumnPage';

const NotAccess = () => {
	const history = useHistory();
	const { t: translate } = useTranslation();
	return (
		<OneColumnPage id="NotAccess" breadcrumb={false}>
			<div className="h-100 d-flex justify-content-center align-items-center">
				<Result
					status="403"
					title="403"
					subTitle={translate('notification.messages.access_denied')}
					extra={
						<Button type="primary" onClick={() => history.push('/')}>
							{translate('notification.actions.back_to_home')}
						</Button>
					}
				/>
			</div>
		</OneColumnPage>
	);
};

export default NotAccess;
