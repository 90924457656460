import React, { FC } from 'react';
import './LoaderOverlay.sass';
import Loader from 'ui/components/Loader/Loader';

interface LoaderOverlayInterface {
	isLoading: boolean;
}
const LoaderOverlay: FC<LoaderOverlayInterface> = ({ isLoading }) => {
	return isLoading ? (
		<div id="dnext-loader-overlay">
			<div className="overlay-loader">
				<Loader size={90} />
			</div>
		</div>
	) : null;
};

export default LoaderOverlay;
