export default {
	name: 'Product',
	name_plural: 'Products',
	new_record_title: 'New product',
	description: 'Commodities and goods you will analyze',
	labels: {
		get_product: 'Get product detail',
		get_related_dataset: 'Get related dataset',
	},
	actions: {
		create_new: 'Create a new product',
	},
	errors: {
		error_get_product: 'An error occured while retrieving data',
		error_get_related_dataset: 'An error occured while retrieving related dataset',
	},
};
