export default {
	title: 'Load data from Excel file',
	sections: {
		global_mapping: 'Global mapping - Context of the Excel file that will apply to every row of every sheet',
		errors: 'Errors that prevent this sheet to be loaded',
		warnings: 'Warnings - Empty and/or erroneous values',
		statistics: 'Statistics',
	},
	actions: {
		click_or_drag_to_upload: 'Click or drag an Excel file here to load it',
		click_or_drag_to_upload_instructions: 'Please select a .xlsx, .xslb, .xlsm, .xls or .csv file',
		start_new_upload: 'Cancel and start a new upload',
	},
};
