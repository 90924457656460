import SimpleFilters from './SimpleFilters';

export default {
	minWidth: 100,
	enableRowGroup: true,
	enablePivot: true,
	enableValue: true,
	sortable: true,
	resizable: true,
	floatingFilter: true,
	filter: 'agTextColumnFilter',
	menuTabs: ['generalMenuTab'],
	filterParams: {
		filterOptions: [
			'contains',
			'notContains',
			'equals',
			'notEqual',
			SimpleFilters.isEmpty,
			SimpleFilters.isNotEmpty,
			'startsWith',
			'endsWith',
		],
		buttons: ['reset'],
		textFormatter(r: any) {
			if (r == null) return null;
			return r
				.toLowerCase()
				.replace(/[àáâãäå]/g, 'a')
				.replace(/æ/g, 'ae')
				.replace(/ç/g, 'c')
				.replace(/[èéêë]/g, 'e')
				.replace(/[ìíîï]/g, 'i')
				.replace(/ñ/g, 'n')
				.replace(/[òóôõö]/g, 'o')
				.replace(/œ/g, 'oe')
				.replace(/[ùúûü]/g, 'u')
				.replace(/[ýÿ]/g, 'y');
		},
		textCustomComparator(filter: any, cellValue: any, filterText: any) {
			const filterTextLoweCase = filterText.toLowerCase();
			const valueLowerCase = cellValue.toString().toLowerCase().replaceAll('-', '');
			switch (filter) {
				case 'contains':
					const filterTextLoweCases = filterTextLoweCase.split(' ');
					let check = true;
					for (let i = 0; i < filterTextLoweCases.length; i++) {
						if (!filterTextLoweCases[i]) continue;
						filterTextLoweCases[i] = filterTextLoweCases[i].replaceAll('-', '');

						check = check && valueLowerCase.includes(filterTextLoweCases[i].toLowerCase());
					}
					return check;
				case 'notContains':
					return valueLowerCase.indexOf(filterTextLoweCase) === -1;
				case 'equals':
					return valueLowerCase === filterTextLoweCase;
				case 'notEqual':
					return valueLowerCase !== filterTextLoweCase;
				case 'startsWith':
					return valueLowerCase.indexOf(filterTextLoweCase) === 0;
				case 'endsWith':
					var index = valueLowerCase.lastIndexOf(filterTextLoweCase);
					return index >= 0 && index === valueLowerCase.length - filterTextLoweCase.length;
				default:
					// should never happen
					return false;
			}
		},
		debounceMs: 200,
	},
	headerComponentParams: {
		template:
			'<div class="ag-cell-label-container" role="presentation">' +
			'  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
			'  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
			'    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
			'    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
			'    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
			'    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
			'    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
			'    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
			'  </div>' +
			'</div>',
	},
};
