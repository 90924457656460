// ant design & icons
import { faMistletoe } from '@fortawesome/pro-light-svg-icons';
// utils
import { server, basePath } from 'src/utils/api-prefix.helper';
// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceRecord: 'quote-type',
	referenceData: true,
	navigateByCode: true,
	iconName: faMistletoe,
	url: `${baseApiUrl}quote-type`,
	urlParams: 'relation=[user]',
	elasticSearchKey: 'QuoteType',
	relatedReferences: [],
	keysMatching: [],
	path: '/quote-type',
	pageTitle: {
		list: 'Quote types',
		action: {
			create: 'New quote type',
			edit: 'Edit quote type',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
};
