import models from './models';
import form from './form';
import view from './view';

export default {
	name: 'Vessel tracking',
	form,
	view,
	models,
};
