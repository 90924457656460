import React from 'react';

import OneColumnPage from 'ui/layout/OneColumnPage/OneColumnPage';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faRoute } from '@fortawesome/pro-light-svg-icons';
import TopNav from '../components/Nav/TopNav/TopNav';

class NotFound extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
		};
	}

	render() {
		return (
			<OneColumnPage id="notFound" breadcrumb={false}>
				<Result
					title="404"
					subTitle="Sorry, the page you visited does not exist."
					extra={
						<Link to="/" className="btn btn-primary">
							Back to home
						</Link>
					}
				/>
			</OneColumnPage>
		);
	}
}

export default NotFound;
