import DataService from 'src/utils/DataService';
import configAuthentication from 'ui/modules/Authentication/config/auth.config';

/**
 * login service
 */
export function getCustomLoginService() {
	return new DataService({
		url: configAuthentication.api.customLogin,
	});
}

/**
 * send challenge request
 */
export function getChallengeService() {
	return new DataService({
		url: configAuthentication.api.challenge,
	});
}

/**
 * change password request
 */
export function changePasswordService() {
	return new DataService({
		url: configAuthentication.api.changePassword,
		token: true,
	});
}

/**
 * old service of get orgs TODO: must be removed
 */
export function retrieveOrgsOldService() {
	return new DataService({
		url: configAuthentication.api.retrieveOrgsOld,
		urlParams: '',
		token: true,
	});
}

/**
 * new service of get orgs
 */
export function retrieveOrgsService() {
	return new DataService({
		url: configAuthentication.api.retrieveOrgs,
		urlParams: '',
		token: true,
		og: true,
	});
}
