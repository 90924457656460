import React from 'react';
import { memoize, sortBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/pro-light-svg-icons';

export const convertData = memoize((data) => {
	const indexedIds = {};
	if (!data) data = [];

	if (!data[0]) data = [];

	// console.time('convertData');
	data.forEach((element) => {
		indexedIds[element.id] = element;
	});

	let results = data.map((e) => {
		const grandParent = [];
		const parent = indexedIds[e.pId];
		e.parentNb = e.parentNb || 0;
		e.order = e.label === e.text ? e.label : `${e.label} ${e.text}`;
		if (parent) {
			e.label = `${parent.text.replace(/ *\([^)]*\) */g, '')} / `; // +e.text;

			// checkGrandparent
			grandParent[0] = indexedIds[parent.pId];
			for (let i = 0; i < 10; i++) {
				if (grandParent[i]) {
					e.parentNb += 1;
					e.label = `${grandParent[i].text.replace(/ *\([^)]*\) */g, '')} / ${e.label}`;
					grandParent[i + 1] = indexedIds[grandParent[i].pId];
				} else break;
			}

			e.order = `${e.label} ${e.text}`;

			e.label = (
				<>
					<span className="parent_element">{e.label}</span>
					{e.text}
				</>
			);
		}

		return e;
	});

	// if reference does't use is_group
	if (results[0]?.isGroup !== undefined) {
		results = results.map((d) => {
			if (d.isGroup && d.doneG !== true) {
				d.label = (
					<>
						<span style={{ marginRight: '5px' }}>
							<FontAwesomeIcon icon={faFolder} />
						</span>
						{d.label}
					</>
				);
				d.doneG = true;
			}

			return d;
		});
	}

	// console.timeEnd('convertData');
	return sortBy(results, ['order']);
});
