export const recordType = 'category';
export const widgetLayout = {
	labelCol: {},
	wrapperCol: {},
};

export const validateMessages = {
	required: 'This field is required',
};

export const dataHealth = 'data_health';
