import DataService from 'utils/DataService';
import config from 'src/config';
import { capitalize } from 'lodash';

// TODO: to be replaced by getAllReferencesData function from reference.helpers
export const getAllReferenceByType = async (
	reference,
	{ isReferenceListLoaded, setReferenceListValues, getReferenceListValues }
) => {
	try {
		let results = [];
		if (!isReferenceListLoaded(reference)) {
			const response = await new DataService({
				url: config.api[reference].url,
				urlParams: "?exclude=[parent]&limit=10000&order=[['name', 'ASC']]",
			}).getAll();
			results = response.data;
			setReferenceListValues(reference, results);
		} else {
			results = getReferenceListValues(reference);
		}

		const { parentField } = config.records[reference];
		return results.map((d) => {
			// Tree structure
			let label = d.name;

			if (d.code) label += ` (${d.code})`;
			return {
				label,
				id: d.id,
				code: d.code,
				value: d.code,
				text: label,
				pId: d[parentField],
				isGroup: d.is_group,
			};
		});
	} catch (err) {
		return null;
	}
};
export const deleteReference = async (reference, id) => {
	try {
		const result = await new DataService({
			url: config.api[reference].url,
			urlParams: '',
		}).delete(id);

		return result;
	} catch (err) {
		return null;
	}
};
export const getUsageReference = async (reference, code) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/usage/${capitalize(reference)}/${encodeURIComponent(code)}`,
			urlParams: '',
		}).getAll();

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const getInvalidReferences = async (reference) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/invalid/${reference}`,
			urlParams: '',
		}).getAll();

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const getRuleset = async (reference) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/ruleset`,
			urlParams: '',
		}).getAll();

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const setRuleset = async (type, data) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/ruleset`,
			urlParams: '',
		}).update(type, data);

		return result.data.result || {};
	} catch (err) {
		return null;
	}
};
export const getRefLinksync = async (reference) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/reference-list-sync`,
			urlParams: '',
		}).getAll();

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const getDuplicateEntries = async (reference) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/duplicates/${reference}`,
			urlParams: '',
		}).getAll();
		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const getMappings = async (payload = {}) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/mapping`,
			urlParams: '',
		}).create(payload);

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const getLastReferences = async (payload = {}) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/mapping/last-creation`,
			urlParams: '',
		}).create(payload);

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const updateRulesReferenceList = async (entryStorage, settings, values) => {
	try {
		let newSettings = settings;
		await Promise.all(
			Object.keys(values).map(async (code) => {
				await setRuleset(code, { validation_rules: values[code] });
				newSettings = newSettings.map((c) => {
					if (c.code === code) return { ...c, validation_rules: JSON.stringify(values[code]) };
					return c;
				});
			})
		);

		localStorage.setItem(entryStorage, JSON.stringify(newSettings));
	} catch (err) {
		return null;
	}
};
export const getReferencesToBeApproved = async () => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/require-approval`,
			urlParams: '',
		}).getAll();

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
export const approveReference = async (payload = [], isApproved) => {
	try {
		const result = await new DataService({
			url: `${config.api.endpoint}/references/require-approval/${isApproved ? 'approved' : 'rejected'}`,
			urlParams: '',
		}).put(payload);

		return result.data.result || [];
	} catch (err) {
		return null;
	}
};
