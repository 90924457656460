import loadExcel from './loadExcel';
import user from './user.js';
import symbol from './symbol.js';
import snd from './snd.js';
import production from './production.js';
import dataset from './Datasets/dataset.js';
import newDataset from './Datasets/newDataset.js';
import location from './location.js';
import currency from './currency.js';
import unit from './unit.js';
import transport_type from './transport_type.js';
import source from './source';
import group from './group.js';
import products from './Products';
import custom_codes from './CustomCodes'; // TODO: Clean this
import custom_code from './custom_code';
import location_group from './location_group';
import query_builder from './query_builder';
import tradeflow from './Tradeflow/tradeflow';
import search from './search';
import trade_flow from './Tradeflow/trade_flow';
import workflow from './Workflows/workflow';
import organization_settings from './OrganizationSettings';
import file from './File';

import auth from './auth';
import freight from './freight';
import sidenav from './sidenav';

export default {
	auth,
	load_excel: loadExcel,
	workflow,
	user,
	symbol,
	dataset,
	newDataset,
	location,
	currency,
	unit,
	snd,
	transport_type,
	search,
	source,
	production,
	group,
	query_builder,
	...products,
	...custom_codes,
	custom_code,
	location_group,
	tradeflow,
	trade_flow,
	organization_settings,
	freight,
	file,
	sidenav,
};
