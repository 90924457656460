// Dependencies
import React, { Component } from 'react';

// shared utils
import * as _ from 'lodash';

// ant design
import { Tag } from 'antd';

export default class tagRenderer extends Component {
	render() {
		let value = this.props.value || this.props.null_value;
		if (!value) return null;
		value = _.split(value, this.props.colDef?.tag_separator || ',');

		const { mapping } = this.props;

		return _.map(value, (tag, index) => (
			<span key={index}>
				<Tag style={{ textAlign: 'center' }} color={mapping ? mapping[tag] : this.props.color}>
					{tag}
				</Tag>
			</span>
		));
	}
}
