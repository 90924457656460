// Dependencies
import React from 'react';
//Ant design & Icons
import { faBox } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utils
import { server, basePath } from 'src/utils/api-prefix.helper';
import _ from 'lodash';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';

// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceRecord: 'product-group',
	groupOf: 'product',
	referenceData: true,
	navigateByCode: true,
	icon: (
		<div className="fa-layers fa-fw">
			<FontAwesomeIcon icon={faBox} transform="shrink-4 up-2 left-3" />
			<FontAwesomeIcon icon={faBox} transform="shrink-7 down-6 right-6" />
		</div>
	),
	url: `${baseApiUrl}product-group`,
	path: '/products-group',
	elasticSearchKey: 'Product',
	relatedReferences: [],
	keysMatching: [],
	pageTitle: {
		list: 'Product groups',
		action: {
			create: 'New product group',
			edit: 'Edit product group',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			cellRendererFramework: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/${data.direct_parent.is_group ? 'products-group' : 'products'}/${
							data.direct_parent.code
						}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
	canMoveParent: true,
	parentField: 'parent_id',
};
