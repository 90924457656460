import configAuthentication from 'ui/modules/Authentication/config/auth.config';

export const getOrg = (askLocalhost = true) => {
	let org = null;
	let url: any = window.location.href;
	if (url && !url.includes('localhost')) {
		url = url.replace('http://', '').replace('https://', '');
		url = url.split('.');
		org = url[0];
		org = org === 'test' || org === 'dev' ? url[1] : url[0];
		if (org === 'test-app' || org === 'app') org = localStorage.getItem('org') || 'dndev';
	}
	if (url.includes('localhost') && askLocalhost) {
		org = 'dndev';
	}
	return org;
};

/**
 * Decode Token
 * @param token
 * @returns Json
 */
export const decodeToken = (token: string) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

/**
 * get date expiration from token
 * @returns date
 */
export const getInitialExpiredAt = () => {
	const decodedToken = getConnectedUser();
	const res = decodedToken && decodedToken.exp ? new Date(decodedToken.exp * 1000) : null;
	return res;
};

/**
 * save current URL after token expiration
 */
export const saveCurrentURL = () => {
	const currentURL = window.location.pathname;
	const org = getOrg();
	localStorage.setItem(`${org}-redirection`, currentURL);
};

/**
 * logout
 * @param setIsAuthenticated
 * @param history
 */
export async function handleLogout(setIsAuthenticated: (val: boolean) => void, history: any, params: string = '') {
	setIsAuthenticated(false);
	localStorage.removeItem('isAuthenticated');
	localStorage.removeItem('token');
	localStorage.removeItem('hide_twitter_ads');
	// do not redirect to login if path is password-recovery
	if (window.location.pathname !== `${configAuthentication.path}/password-recovery`) {
		history.push(`/login${params || ''}`);
	}
}

export const checkExpiredToken = (setIsAuthenticated: (val: boolean) => void, history: any) => {
	const initialToken = getInitialExpiredAt();
	if (initialToken) {
		const currentDate = new Date();
		const timeDifference = initialToken.getTime() - currentDate.getTime();

		if (timeDifference > 0) {
			return setTimeout(() => {
				saveCurrentURL();
				handleLogout(setIsAuthenticated, history, '?token=expired');
			}, timeDifference);
		}
	}
};

//redirection redirection 401 error
export async function redirectionUnauthorized() {
	saveCurrentURL();
	localStorage.removeItem('isAuthenticated');
	localStorage.removeItem('token');
	localStorage.removeItem('hide_twitter_ads');
	window.location.replace('/login?token=expired');
}

/**
 * get token decoded
 * @returns date
 */
export const getConnectedUser = () => {
	const token = localStorage.getItem('token');
	if (token) {
		const decodedToken = decodeToken(token);
		return decodedToken || null;
	}
	return null;
};

export const getBookmarksFromLocalStorage = (): string[] => {
	const bookmarkStorage = window.localStorage.getItem('bookmarks');
	if (bookmarkStorage) {
		return bookmarkStorage.split(',');
	}

	return [];
};

export const getUserConnected = () => {
	const user = localStorage.getItem('user');
	return user ? JSON.parse(user) : null;
};

export const hasToken = () => {
	const token = localStorage.getItem('token');
	return token && JSON.parse(token)?.jwtToken;
};

/**
 * clear all the model filters of the ag grid
 *  @returns
 */
// export const clearListLocalStorage = () => {
// 	for (let i = localStorage.length - 1; i >= 0; i--) {
// 		const key = localStorage.key(i);
// 		if (key && key.includes("_list_state")) {
// 			localStorage.removeItem(key);
// 		}
// 	}
// };
