export default {
	title: 'Log in',
	messages: {
		no_account: "Don't have an account?",
		lost_password: 'Lost password?',
		resend_code: "Didn't receive a code?",
		password_min_requirement:
			'Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one number',
		please_confirm_password: 'Please confirm your password',
		first_name: 'First name field must be filled in',
		last_name: 'Last name field must be filled in',
		confirm_password_not_match: 'The two passwords that you entered do not match',
		confirm_password_change: 'Confirm password change ',
		verification_token: 'Please enter the verification code you just received by email',
		verification_token_placeholder: 'Code',
		new_code_sent: 'A new code has been sent',
		request_code_disabled: 'Please wait for 30 seconds before requesting another code.',
		username_placeholder: 'Email',
		changePasswordTitle: 'Change your password',
		emailLabel: 'Your email',
		verificationCodeLabel: 'Verification code',
		verificationCodeExtra: 'An email with a verification code was just sent to you',
		confirmPasswordValidationMessage: 'Please confirm your password!',
		confirmPasswordErrorMessage: 'The two passwords that you entered do not match',
		confirmNewPassword: 'Confirm new password',
		passwordResetSuccess: 'Your password has been reset.',
		clickHereToLogin: 'Click here to login',
		profileUpdated: 'Success',
	},
	errors: {
		missing_username: 'Please enter your email',
		missing_password: 'Please enter your password',
		missing_token: 'Please enter your verification code',
		error_getting_permissions: 'An error occured when getting permissions',
	},
	actions: {
		signup: 'Sign up',
		signin: 'Log in',
		recover_password: 'Reset password',
		resend_code: 'Resend code',
		resending_code: 'Resending code',
		remember_device: 'Remember this device',
	},
	info: {
		password_must_change: 'Your password must be changed. Please enter a new password below.',
	},
	password_recovery: {
		title: 'Reset password',
		description: 'Please enter your email address below.',
		email_placeholder: 'Your Email',
		confirm_email: 'Confirm email',
		back_to_login: 'Back to login',
		already_have_code: 'Already have a code?',
	},
};
