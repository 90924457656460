export default {
	name: 'Email ruleset',
	name_plural: 'Emails ruleset',
	new_record_title: 'New email ruleset',
	actions: {
		create_new: 'New email ruleset',
		add_new_delivery: 'Add a new delivery method',
		add_new_metadata: 'Add a new metadata',
	},
	labels: {
		delivery: 'Delivery',
		dropbox_folder: 'Dropbox folder/',
		define_recipient_emails: 'Define the list of recipient emails',
		define_name: 'Define file name',
		define_file_module: 'Define metadata of file',
		email_recipients: 'Email a full copy of the email to other recipients',
		dropbox: 'Send a copy of the email to Dropbox (EML format)',
		dropbox_attachment: 'Send a copy of each matching attachment to Dropbox',
		script_dropbox: 'Send the result of the script execution to dropbox (using email)',
		script_dropbox_attachment: 'Send the result of the script execution to dropbox (using attachment)',
		send_attachment_files_module: 'Send the attachment to files module',
		send_email_files_module: 'Send the email to files module',
		rules: 'Rules',
	},
	infos: {
		multiple_addresses: "You can define multiple email addresses by separating them with ';'.",
		example: 'Example',
		emails_example: 'example@expl.com;example2@expl.com',
		multiple_references_1: 'You can define multiple metadata references such as source, location, and product ',
		multiple_references_2: 'before sending the attachment to the file module.',
		pattern: 'You can define the name of the file by using those Patterns',
		pattern_example: '{filename}-{receiveddate-yyyy-mm-dd}.{extension}',
		pattern_path: "The path is defined as follows with '/': EXAMPLE/CONTENT/FOLDER",
	},
	messages: {
		error_get_email_ruleset: 'An error has occured when getting email ruleset detail',
		error_create_script: 'An error has occured when creating the script',
		error_update_script: 'An error has occured when updating the script',
		error_get_users: 'An error has occured when getting users',
	},
};
