export default {
	name: 'File',
	name_plural: 'Files',
	actions: {
		add_virtual_folder: 'Add a virtual	folder',
		bulk_upload: 'Bulk upload files',
		edit_settings_folder: 'Edit folder settings',
		upload_file: 'Upload a new file',
		email_ruleset: 'Add email ruleset',
		delete_folder: 'Delete folder',
		visualize_file: 'Visualize file',
		delete_files: 'Delete file',
		edit_file: 'Edit metadata',
		download_file: 'Download file',
		add_filter: 'Add filter',
		add_level: 'Add level',
		remove_filter: 'Remove filter',
		add_reference: 'Add reference',
		remove_reference: 'Remove filter',
		remove_rule: 'Remove rule',
		save: 'Save',
		get_my_folders: 'View only my files',
		search: 'Search',
		clear_all: 'Clear all',
		delete_multiple: 'Delete',
		edit_files: 'Edit files',
		edit_privacy_files: 'Edit privacy settings',
		cancel: 'Cancel',
		next: 'Next',
		previous: 'Previous',
		start_uploading_files: 'Start uploading files',
		run_in_background: 'Run in background',
		go_back: 'Go back',
	},
	labels: {
		search_placeholder: 'Search term - e.g: Stark Shipping in sources ...',
		root: 'root',
		filters: 'Filters',
		drag_file: 'Please make sure to drag or click only one file into this area for uploading',
		metadata: 'Metadata',
		reference: 'References',
		refresh_references: 'Refresh references',
		no_folders_to_map: 'No folders to map',
		results: 'results',
		drag_bulk_file: 'Click or drag multiple file or a zip to this area to upload',
		exist_file: 'Already existing',
		private_file: 'Private',
		public_file: 'Public',
		selected_files: 'Selected files',
		files_privacy: "Set files privacy into 'Private' or 'Public'",
	},
	confirms: {
		delete_multiple: 'Are you sure to delete the selected rows ?',
		delete_folder: 'Are you sure to delete this folder ?',
		delete_files: 'Are you sure to delete this file ?',
	},
	messages: {
		internal_error_server: 'Internal Server Error',
		file_exist: 'File Already existing',
		warning: 'Warning',
		attention: 'Attention!',
		note: 'Note',
		validation_field: 'Please fill this field',
		validation: 'Please fill this field or delete it',
		invalid_form: 'Form is not valid, please fill all required fields',
		success_add: 'Folder successfully added',
		success_update: 'Folder successfully updated',
		success_update_files: 'File(s) successfully updated',
		warning_update: 'Folder should not be the parent of it self',
		success_delete_folders: 'Folder(s) successfully deleted',
		success_delete_files: 'Files(s) successfully deleted',
		warning_delete_folders: 'Please select a folder(s) to delete',
		warning_edit_folder: 'Please select a folder to edit',
		warning_delete_files: 'Please select a file(s) to delete',
		warning_upload_files: 'Please select file(s) to upload',
		warning_download_file: 'Please select file to upload',
		warning_download_file_not_found: 'File not found',
		waiting_assign_url: 'Please wait assigning url',
		success_upload: 'File successfully uploaded',
		failed_upload: 'File Upload Error: Unable to upload the file(s)',
		failed_read_file: 'File reader Error: Unable to read the file',
		success_bulk_upload: 'All files are successfully uploaded',
		failed_bulk_upload: 'All files already exist. No further action required.',
		warning_bulk_upload: 'Some files duplicated, others successfully uploaded.',
		bad_extension: 'Bad mimeType of file',
		damaged_file: 'This file appears to be damaged and cannot be downloaded.',
		empty_rows: 'No row is selected',
		empty_files: 'No file is selected',
		empty_stats: 'No data to show ',
		no_signed_url: 'No signed URL provided or no files to upload',
		no_metadata: 'No metadata to map',
		created_At_warning:
			'All created dates of the files will be overwritten by the one you entered. Do you want to continue?',
		updated_At_warning:
			'All updated dates of the files will be overwritten by the one you entered. Do you want to continue?',
		edit_files_warning: 'You are about to edit the metadata of all the selected files. Do you want to continue?.',
		info_selected_files: 'The displayed metadata represents common information among selected files.',
		info_edit_files: 'Make sure to review and edit carefully. Changes will be applied to all.',
		info_edit_privacy:
			'Modifying privacy settings will affect all selected files. Ensure you have reviewed your selection before proceeding, as changes cannot be undone.',
		files_uploading_in_background: 'Uploading files in the background. Please wait...',
		files_fetching_in_background: 'Fetching files in the background. Please wait...',
		warning_visualize_file_1: "Oops! It seems like we can't preview this file yet.",
		warning_visualize_file_2: 'Please download it to view its contents.',
		error_visualize_file_1: 'Uh-oh! This file appears to be damaged and cannot be opened.',
		error_visualize_file_2: 'Please check the file or try downloading it again.',
		error_visualize_image: 'Error loading the image. It may be damaged or not found.',
		error_visualize_pdf: 'Error loading the pdf. It may be damaged or not found.',
		warning_upload_in_progress: 'Uploading files in progress. Please wait for completion. Thanks!',
		warning_email_rules: 'Please make sure to define at least one rule before submitting the email rule set form.',
		warning_email_deliveries:
			'Please make sure all Deliveries files methods have at least one metadata with reference "source".',
	},
	info: {
		navigation:
			"Click on 'root' to return to the main folder. Breadcrumbs dynamically update to display your current path, revealing the selected folder and its contents.",
		filters:
			"Use this feature to refine the files displayed in the virtual folder. Apply criteria like file type, date range, or name. Once a filter is set, only files meeting the specified conditions will appear. For instance, setting a PDF filter will show only files with the '.pdf' extension.",
		group_by:
			"After applying filters, use the 'Group By' function to organize filtered files into subgroups based on specific attributes. For example, grouping by 'Year' organizes files in the virtual folder by their creation year, making it easy to locate files based on criteria like year, month, source, and more.",
		run_in_background:
			'the file upload process will continue even if you navigate away from the page or engage in other activities within the module.',
		bulk_upload:
			'Easily upload many files at once! You can set shared metadata for all or customize details for each file separately.',
	},
};
