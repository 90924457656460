export default {
	dlineup: {
		name: 'DLineup',
		name_plural: 'DLineup',
		new_record_title: 'New DLineup',
		actions: {
			create_new: 'Create a new DLineup',
		},
		no_x: 'No DLineup',
	},
};
