import React from 'react';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';
import { Space, Tag } from 'antd';
import _ from 'lodash';
import { IUser } from 'ui/modules/Core/types/interfaces/user.interface';
import { centerCellStyle } from 'src/utils/AGGridFilters/FilterParams';

export default {
	referenceData: false,
	iconName: faUser,
	url: `${server}${getApiPrefix()}/users`,
	path: '/users',
	parentPage: 'pages.organization_settings.title',
	pageTitle: {
		list: 'Users',
		action: {
			create: 'New user',
			edit: 'Edit user',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'first_name',
		},
		{
			headerName: 'Family name',
			field: 'last_name',
		},
		{
			headerName: 'Active',
			field: 'enabled',
			cellStyle: centerCellStyle,
			filterValueGetter: ({ data }: { data: IUser }) => {
				return data.enabled ? 'Active' : 'Inactive';
			},
			cellRendererFramework: ({ data }: { data: IUser }) => {
				return (
					<Tag color={data.enabled ? 'success' : 'error'} style={{ width: 70, textAlign: 'center' }}>
						{data.enabled ? 'Active' : 'Inactive'}
					</Tag>
				);
			},
			mustHasRight: true,
		},
		{
			headerName: 'Email',
			field: 'email',
			type: 'email',
		},
		{
			headerName: 'Roles',
			field: 'roles',
			minWidth: 270,
			cellStyle: centerCellStyle,
			filterValueGetter: ({ data }: { data: IUser }) => {
				return (data?.roles || []).join('  ');
			},
			cellRendererFramework: ({ data }: { data: IUser }) => {
				if (data?.roles?.length) {
					return (
						<Space>
							{data.roles.slice(0, 2).map((el) => {
								return (
									<Tag key={el} style={{ marginRight: 0 }}>
										{_.upperFirst(_.toLower(_.replace(el, '_', ' ')))}
									</Tag>
								);
							})}
							{data.roles.length > 2 && <Tag>{`+${data.roles.length - 2}`}</Tag>}
						</Space>
					);
				}
				return '_';
			},
			mustHasRight: true,
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	api: {
		roles: `${server}${getApiPrefix()}/user-management/roles`,
		permissions: `${server}${getApiPrefix()}/user-management/permissions`,
		myPermissions: `${server}${getApiPrefix()}/user-management/permissions/user`,
	},
};
