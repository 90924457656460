import DataService from 'src/utils/DataService';
import businessCategoryConfig from 'ui/modules/Core/config/business-category.config';
import { getAllDashboardsService } from 'ui/modules/Dashboards/services/dashboard.service';

export async function getBusinessCategoryService() {
	const res = await new DataService({
		url: businessCategoryConfig.url,
		urlParams: '',
		token: true,
	}).getAll();

	const configuredCategories = res?.data || [];

	// retrieve all dashboards to whos counts per category
	const dashboards = await getAllDashboardsService();
	const dashboardsPerCategory = (dashboards?.data?.result || []).reduce((acc, dashboard) => {
		dashboard.business_categories.forEach((category) => {
			if (!acc[category.code]) {
				acc[category.code] = 0;
			}
			acc[category.code]++;
		});
		return acc;
	}, {});

	// add the count to the categories
	let total = 0;
	configuredCategories.forEach((category) => {
		category.dashboard_count = dashboardsPerCategory[category.code] || 0;
		total += category.dashboard_count;
	});

	// add a default all category
	const allCategory = {
		id: '*',
		code: '*',
		dashboard_count: total,
		name: 'All dashboards',
		type: 'business_category',
	};

	configuredCategories.unshift(allCategory);

	return res;
}
