import _ from 'lodash';
import { IDataset } from 'ui/modules/Core/types/interfaces/dataset.interface';
import { IWorkflow } from 'ui/modules/Core/types/interfaces/workflow.interface';
import { ITradeflow } from 'ui/modules/Core/types/interfaces/tradeflow.interface';
import { IResourceGroup } from 'ui/modules/OrganizationSettings/types/interfaces/resource-group.interface';

/**
 * get payload to be send  to the payload od apply tags
 * @param {*} resourceType
 * @returns
 */
export const getPayloadApplyLabel = (
	resourceType: 'dataset' | 'tradeflow' | 'workflow' | 'resource_group',
	selectedRows: IWorkflow[] | IDataset[] | ITradeflow[] | IResourceGroup[],
	tags: { checked: boolean; indeterminate: boolean }[]
) => {
	const filteredTags = _.pickBy(tags, (obj) => obj.checked || obj.indeterminate);
	const label_ids = _.keys(filteredTags);
	let resource_ids: string[] = [];
	if (resourceType === 'workflow') {
		resource_ids = _.compact(selectedRows.map((el) => (el as IWorkflow)?.dataset?.id || null));
	} else {
		resource_ids = _.compact(selectedRows.map((el) => el.id));
	}

	return { resourceType, resource_ids, label_ids };
};
