export default {
	name: 'Ingest workflow',
	name_plural: 'Ingest workflows',
	state: {
		stopped: 'Disabled',
		runnable: 'Active',
		standby: 'Pause',
	},
	settings: {
		title: 'Incoming data management',
		interactive_mode: 'Interactive mode',
		must_discard_payload: 'You have to discard your current data before selecting another one',
		discard_current_payload: 'Discard current data',
		current_payload: 'Current payload',
		sample: 'Sample',
		is_edit_mode_on: 'No active execution. Settings can be changed but not previewed',
		is_edit_mode_off: 'Execution in progress. Settings can be changed and previewed',
		toggle_runnable: 'This will define the state of your workflow: active/deactivated',
		switch_mode:
			'Switch from one mode to another, allowing you to edit the workflow without having to go through the validation steps again',
		actions: {
			list_payloads: 'Incoming data list',
			discard: 'Discard incoming data',
			discard_explanation:
				'Stop immediately current execution and discard data. If your workflow is not stopped or paused, it will be ready to handle newly received data.',
			execute: 'Execution on email attachment',
		},
		run_workflow: 'Execute this payload',
		execute_script: 'Execute script',
		switch_payload: 'Use this data as input to the workflow',
		download_workflow: 'Download payload',
		select_forecast: 'Select forecast',
	},
	error: {
		emptyRows: 'Some datas are missing in row(s)',
		incompatible_types: 'Unable to apply the format specified on values(s)',
		failed_column: 'Column(s) does (do) not exist in dataset',
		missing_column: 'The mentioned column does not exist in the input columns',
		missing_value: 'Some values are missing in row(s)',
		formatting_failures: 'Unable to apply the format specified on values(s)',
		reused_column: 'Column(s) has been reused',
		handle_method_missing: 'Unable to execute this script. Malformed syntax or handle() method missing.',
		script_execution_completed:
			'Script execution completed but the output has been refused by the API. Check the format of data you return.',
		script_execution_failed: 'Script execution failed.',
		file_loading_completed: 'File loading encountered errors',
		file_loading_failed: 'File loading encountered errors',
		file_conversion_failed: 'File could not be converted',
		formatting_failure: 'Unable to format column according to settings',
		set_to_null: 'Column has been nullified',
		invalid_format: 'Invalid format',
		invalid_file_format: 'Unable to parse file according to expected format',
		invalid_result: 'API result is not valid',
		invalid_file: 'File does not exist or is unreachable',
		no_data_found: 'API returned no usable data',
		error_loading: 'Loading step failed',
		error_exporting: 'Exporting step failed',
		error_namemapping: 'Columns mapping step failed',
		error_valuemapping: 'Value mapping step failed',
		error_filtering: 'Filtering step failed',
		workflow_failed: 'Workflow execution failed',
		setup_failed: 'Unable to prepare workflow',
		timeout: 'Execution timeout (15 minutes)',
		no_user_specified_or_invalid_user_token: 'No selected user or user token expired',
		workflow_step_ended: 'Workflow step ended with errors',
		no_matching_email: 'No mail found with this configuration',
		multiple_error_appears:
			'Several errors were found. You will find them in the tab "Issues".' +
			'\n' +
			' Press "Save" to proceed to a new validation',
		error_payload_download: 'An error occured while downloading payload',
		please_select_forecast: 'Please select a forecast to proceed',
	},
	success: {
		script_execution_completed: 'Script execution completed successfully',
		file_loading_completed: 'File loading completed successfully',
		attachment_api_result_received: 'The API analyzed attachment successfully',
		api_call_succeeded: 'API call succeeded',
		workflow_succeeded: 'Workflow ended successfully',
		inputs_updated: 'Available columns have been updated',
		workflow_step_ended: 'Workflow step ended successfully',
		success_payload_download: 'Payload downloaded successfully',
	},
	strategy: {
		erase: 'Replace the whole dataset content by the newly loaded content',
		append: 'Append all the rows to the existing dataset with no duplicate check',
		replace: 'Replace old rows that match all conditions below',
	},
	message: {
		non_interactive_tooltip:
			'In order to preview input/output, you must start an interactive execution by either selecting incoming data or load new from the loading step',
		choose_columns: 'Choose which columns you want to export',
		all_columns: 'All Columns',
		define_strategy: 'Define how incoming data should impact historical data',
	},
	info: {
		trigger_unavailable_in_interactive_mode: "Trigger settings can't be changed during an interactive execution",
		loader_unavailable_in_interactive_mode: "Loader settings can't be changed during an interactive execution",
		no_config_found:
			'No configuration found for your workflow.  You have the possibility to set up your workflow configuration via the different settings proposed. \n \n For more information: ....',
		job_queued: 'Execution planned, please wait...',
		edit_mode: 'You are currently in edit mode, without workflow execution',
		script_execution_started: 'Script execution has started',
		fetch_file_started: 'File fetching started',
		file_conversion_started: 'File conversion started',
		file_loading_started: 'File loading started',
		attachment_sent_to_api: 'Attachment analysis started',
		api_call_started: 'API call started',
		workflow_started: 'Workflow started',
		running_loading: 'Loading step in progress',
		running_namemapping: 'Column mapping step in progress',
		running_valuemapping: 'Value mapping step in progress',
		running_filtering: 'Filtering step in progress',
		running_exporting: 'Exporting step in progress',
		extracting_email_content: 'Email content extraction in progress',
		workflow_step_updated: 'Workflow step has been updated',
		workflow_step_started: 'Workflow step has started',
		script_notification: 'Workflow script message received',
		interactive_mode_on:
			'You are currently in interactive mode, which allows you to preview how your #settings will impact real incoming data and have a realtime feedback of issues',
		maximum_percent_wipe: 'Maximum percent of rows that can be wiped from historical data',
		conditions: 'Conditions',
		run_workflow_until_end: 'The step has been correctly saved, your workflow will now be executed until the end',
		will_discard_payload:
			'This action will stop the current execution and start a new one with this new configuration',
		autorun:
			'The autorun of your workflow is now disabled.' +
			'\n' +
			' Please, finish the current workflow until the end to make it runnable automatically',
	},
	loader_type: {
		'script': 'Execute provided Python script',
		'python_3-8': 'Execute provided Python 3.8 script',
		'r-script': 'Execute provided R script',
		'dropbox': 'Fetch a dropbox file',
		'dropbox-python-script': 'Fetch a dropbox and execute a python script',
		'xlsx': 'Fetch an Excel XLSX file',
		'csv': 'Fetch an Excel CSV file',
		'api': 'Call an API endpoint with a standardized output format',
		'none': 'No loader',
		'null': 'No loader',
		'raw': 'Load the file as-is',
		'sharepoint-python-script': 'Sharepoint file with a Python script',
	},
	trigger_type: {
		frequency: 'Automatically triggered based on schedule rules',
		email: 'Automatically triggered when an email is received',
		none: 'No trigger (e.g: manual file loading or external scheduling)',
		null: 'No trigger (e.g: manual file loading or external scheduling)',
		excel_template: 'Use an Excel template as a trigger',
	},
	list: {
		trigger: {
			none: 'Manual',
			frequency: 'Frequency',
			email: 'Emails',
			organization_share: 'Shared dataset',
			excel_template: 'Excel',
		},
		loader: {
			'none': 'No loader',
			'script': 'Python script',
			'python_3-8': 'Python 3.8 script',
			'r-script': 'R script',
			'api': 'External API',
			'xlsx': 'Excel extractor',
			'csv': 'CSV extractor',
			'dropbox': 'Dropbox file',
			'sharepoint': 'Sharepoint file',
			'raw': 'Direct load',
			'dropbox-python-script': 'Dropbox python script',
		},
	},
	types: {
		ingest: {
			name_plural: 'Ingest Workflows',
		},
		transform: {
			name_plural: 'Transform Workflows',
		},
	},
	actions: {
		add_new_schedule: 'Add a new schedule',
		add_new_rule: 'Add a new rule',
		execute_immediately: 'Execute immediately',
		execute_immediately_warning:
			'Clicking on it will discard your current data and wait for new ones to be available.',
		exec_on_last_email: 'Execute immediately on last matching email',
		upload_file: 'Upload an Excel file manually',
		simulating: 'Simulate...',
		simulation_update_strategy: 'Simulate',
		add_new_or: 'Add a new "OR" condition block',
	},

	triggers: {
		title: 'Define what will trigger this workflow',
		frequency: 'Frequency',
		email: 'Email',
		organization_share: 'Updates from shared datasets',
		none: 'Manual Upload/ Manual file upload',
		excel_template: 'Excel templates',
	},
	email_config: {
		subject: 'Subject',
		sender_email: 'Sender email',
		attachment_name: 'Attachment name',
		attachment: 'Attachment',
	},
	email_trigger: {
		title: 'Define how do you want to load it',
		attachment: 'Use matching attachment',
		body_txt: 'Use email body as text',
		body_html: 'Use email body as HTML',
		body_raw: 'Use email body as raw',
		api: 'Call an API endpoint with a standardized input/output format',
		csv_file: 'Extract a CSV file',
		xlsx: 'Extract an Excel file',
		raw: 'Save the file as-is',
	},
	loader: {
		title: 'Define the data loader mechanism',
		api_endpoint: 'Call an API endpoint with a standardized output format',
		python: 'Python script',
		python_3_8: 'Python 3.8 script',
		r: 'R script',
		raw: 'Use the dataset as-is',
		dropbox: 'Excel file from Dropbox (.xlsx, .xlsb, .xlsm, .xls)',
		dropbox_python: 'Dropbox file with a Python script',
		csv: 'Excel CSV file',
		xlsx: 'Excel file (.xlsx, .xlsb, .xlsm, .xls)',
		sharepoint: 'Excel file from Sharepoint (.xlsx, .xlsb, .xlsm, .xls)',
		sharepoint_python: 'Sharepoint file with a Python script',
	},
	misc: {
		sheet_name_number: 'Sheet name or number',
		header_line_number: 'Header line number',
		column_separator: 'Column separator',
	},
	steps: {
		map_columns: {
			title: 'Map columns',
			columns_matching_mapping: 'Columns matching / Columns mapping',
			columns_matching_mapping_tooltip: "Define how the input columns match the dataset's columns",
			error_formatting: 'Errors formatting',
			error_formatting_tooltip: 'Define how to handle unformatted and missing value inputs',
		},
		map_values: {
			title: 'Map values',
			suggestion_found: 'Suggestion found',
			suggestion_found_tooltip:
				'Define the strategy you would like to adopt when a new value appears and then system...',
			no_suggestion_found: 'No suggestion found',
			no_suggestion_found_tooltip:
				'Define the strategy you would like to adopt when a new value appears and then system...',
			global_tooltip: 'Make the mapping global in datasets',
		},
	},
	view: {
		last_update: 'Last Update',
		last_execution: 'Last execution',
		last_extraction: 'Last extraction',
	},
};
