export default {
	DetailsPanel: {
		tabs: {
			selection: {
				title: 'Selection',
				yearlytrend: {
					title: 'Yearly trend',
					infos: 'Yearly trend',
				},
				deviation: {
					title: 'Deviation',
					infos: 'Deviation',
				},
			},
			summary: {
				title: 'Summary',
			},
		},
		infos: {
			r2: 'r2',
		},
	},
	seeDetails: 'Click on any cell to get details',
	showSummary: 'Switch to visible in view setting for the summary',
	forecastChanges: {
		title: 'Forecast changes',
		unsaved: 'unsaved forecast updates',
		delete_all: 'Delete all',
	},
	ForecastLayer: {
		title: 'Forecast layers',
		infos: 'top priority above, lowest priority below',
		editing: 'Editing',
		no_forecat: "No forecast found or the selected forecast can't be modified",
		or_create_new_one: 'or create a new one',
		add_forecast: 'Please add a forecast to visualize it',
		currently_editing: 'You are currently editing forecast',
		create: 'Create',
		manage: 'Manage',
	},
};
