// Dependencies
import React from 'react';
//Ant design & Icons
import { faBox, faFolder } from '@fortawesome/pro-light-svg-icons';
//utils
import { server, basePath } from 'src/utils/api-prefix.helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceRecord: 'product-category',
	referenceData: true,
	navigateByCode: true,
	groupOf: 'product-category',
	iconName: faBox,
	icon: (
		<div className="fa-layers fa-fw">
			<FontAwesomeIcon icon={faBox} transform="shrink-4 up-2 left-3" />
			<FontAwesomeIcon icon={faFolder} transform="shrink-6 down-6 right-7" />
		</div>
	),
	url: `${baseApiUrl}product-category`,
	elasticSearchKey: 'ProductCategory',
	relatedReferences: [],
	keysMatching: [],
	path: '/product-categories',
	pageTitle: {
		list: 'Product categories',
		action: {
			create: 'New product category',
			edit: 'Edit product category',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: false,
	canEdit: false,
	canDelete: false,
	canArchive: false,
	canMoveParent: false,
};
