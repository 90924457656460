import React from 'react';

import GlobalError from '../ui/pages/GlobalError';

class GlobalErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// log the error to an error reporting service
	}

	render() {
		if (this.state.hasError) {
			return <GlobalError {...this.props} />;
		}

		return this.props.children;
	}
}

export default GlobalErrorBoundary;
