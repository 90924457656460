//Ant design & Icons
import { faMoneyCheckAlt } from '@fortawesome/pro-light-svg-icons';
//utils
import { server, basePath } from 'src/utils/api-prefix.helper';
// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceRecord: 'price-type',
	referenceData: true,
	navigateByCode: true,
	iconName: faMoneyCheckAlt,
	url: `${baseApiUrl}price-type`,
	urlParams: 'relation=[user]',
	path: '/price-type',
	elasticSearchKey: 'PriceType',
	relatedReferences: [],
	keysMatching: [],
	pageTitle: {
		list: 'Price types',
		action: {
			create: 'New price type',
			edit: 'Edit price type',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
};
