// Dependencies
import React, { Component } from 'react';
// shared utils
import { split, map } from 'lodash';
// ant design
import { Tag } from 'antd';

export default class labelRenderer extends Component {
	render() {
		let value = this.props.value || this.props.null_value;
		if (!value) return null;

		value = split(value, this.props.colDef?.tag_separator || ',');

		return map(value, (label, index) => (
			<span key={index}>
				<Tag style={{ textAlign: 'center' }}>{label}</Tag>
			</span>
		));
	}
}
