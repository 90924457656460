import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AutoComplete } from 'antd';

export default class AutoCompleteFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			text: '',
		};

		this.valueGetter = this.props.valueGetter;

		this.onChange = this.onChange.bind(this);
	}

	isFilterActive() {
		return this.state.text != null && this.state.text !== '';
	}

	doesFilterPass(params) {
		return this.state.text
			.toLowerCase()
			.split(' ')
			.every((filterWord) => this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0);
	}

	getModel() {
		return { value: this.state.text };
	}

	setModel(model) {
		this.setState({ text: model ? model.value : '' });
	}

	afterGuiAttached(params) {
		this.focus();
	}

	focus() {
		window.setTimeout(() => {
			const container = ReactDOM.findDOMNode(this.refs.input);

			if (container) {
				container.focus();
			}
		});
	}

	componentMethod(message) {
		alert(`Alert from PartialMatchFilterComponent: ${message}`);
	}

	onChange(event) {
		const newValue = event.target.value;

		if (this.state.text !== newValue) {
			this.setState(
				{
					text: newValue,
				},
				() => {
					this.props.filterChangedCallback();
				}
			);
		}
	}

	render() {
		return <AutoComplete style={{ width: '100%' }} />;
	}
}
