export default {
	name: 'Transform workflow',
	inputName: 'Transform workflow Input',
	outputName: 'Transform workflow Output',
	name_plural: 'Transform workflows',
	input_name_plural: 'Transform workflows Inputs',
	outputs_name_plural: 'Transform workflows Outputs',
	new_record_title: 'New transform workflow',
	actions: {
		create_new: 'Create a new transform workflow',
	},
};
