import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, MenuProps, Avatar, Tooltip } from 'antd';
import { getDefaultMenuKey, getNavItems } from 'ui/components/Nav/Sidenav/helpers/navItems.helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/libs/contextLib';
import { useToasts } from 'react-toast-notifications';
import { pushNotification } from 'src/utils/PushNotification';
import './Sidenav.sass';

type MenuItem = Required<MenuProps>['items'][number];
const Sidenav = (props: any) => {
	const { isAuthenticated, handleLogout, userInitials, user, myPermissions, businessCategories } =
		useUserContext() as any;
	const history = useHistory();
	const location = useLocation();
	const { t: translate } = useTranslation();
	const items: MenuItem[] = getNavItems(
		history,
		translate,
		user,
		isAuthenticated,
		props.bookmarks,
		myPermissions,
		businessCategories
	);
	const [selectedKeys, setSelectedKeys] = useState<any>([]);
	const { addToast } = useToasts();

	//select current item
	useLayoutEffect(() => {
		setSelectedKeys(getDefaultMenuKey(items));
	}, []);

	useEffect(() => {
		if (location?.pathname) {
			setSelectedKeys(getDefaultMenuKey(items));
		}
	}, [location]);

	//notification
	useEffect(() => {
		const notificationSubscription = props.subscribeToType('system', receiveNotification);
		return () => {
			props.unsubscribeFromType('system', notificationSubscription);
		};
	}, []);

	// Notification handler
	function receiveNotification(event: any) {
		let { details } = event;
		if (typeof details === 'object') {
			details = JSON.stringify(details);
		}
		pushNotification(addToast, translate(`system.${event.type}.${event.code}`), event.type, details);
	}

	if (isAuthenticated) {
		return (
			<div id="side-nav-dnext" className="side-nav-block">
				<img
					src="/Logo-S-White.svg"
					className="cursor-pointer menu-logo"
					onClick={() => {
						history.push('/');
						setSelectedKeys([]);
					}}
				/>
				<div className="side-nav-content">
					<Menu mode="inline" inlineCollapsed={true} items={items} selectedKeys={selectedKeys} />
				</div>
				<div className="side-nav-bottom">
					<p className="org-brand">
						{localStorage.getItem('org') &&
							window.location.href.includes('localhost') &&
							localStorage.getItem('org')}
					</p>
					<div
						className="m-2 cursor-pointer"
						onClick={() => {
							history.push('/profile');
							setSelectedKeys([]);
						}}
					>
						<Tooltip title={translate('pages.sidenav.user_profile')} placement="right">
							<Avatar>{userInitials}</Avatar>
						</Tooltip>
					</div>
					<div
						className="cursor-pointer"
						onClick={() => {
							history.push('/support');
							setSelectedKeys([]);
						}}
					>
						<Tooltip title={translate('pages.sidenav.support')} placement="right">
							<FontAwesomeIcon icon={faInfoCircle} size="lg" />
						</Tooltip>
					</div>
					<div
						className="cursor-pointer"
						onClick={() => {
							handleLogout();
							setSelectedKeys([]);
						}}
					>
						<Tooltip title={translate('pages.sidenav.logout')} placement="right">
							<FontAwesomeIcon icon={faPowerOff} size="lg" />
						</Tooltip>
					</div>
				</div>
			</div>
		);
	}
	return null;
};
export default Sidenav;
