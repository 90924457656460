export default {
	newRecordUrlValue: 'new',
	editRecordUrlValue: 'edit',
	cloneRecordUrlValue: 'clone',
	settingsRecordValue: 'settings',
	reportingRecordValue: 'reporting',
	aggregationRecordValue: 'aggregation',
	gridRecordValue: 'grid',
	statsRecord: 'stats',
};
