export default {
	name: 'Production',
	name_plural: 'Productions',
	messages: {
		success: {
			production_refresh_completed: 'Production has been successfully refreshed',
			refresh_completed: 'Production has been successfully refreshed',
			update_completed: 'The update of the forecast is finished. Your forecasts will be reloaded',
		},
		info: {
			aggregating_datasets: 'Aggregating datasets',
			preparing_production: 'Transforming data for production',
			preparing_data: 'Transforming data for production',
			exporting_data: 'Exporting data',
			bulk_update_awaiting_update: 'Bulk update done. Awaiting forecast update',
		},
		error: {
			production_refresh_failed: 'Production encountered some errors during refresh',
			refresh_failed: 'Production encountered some errors during refresh',
			no_data:
				'No data was found for production. Please check your configuration.  This may be due to a configuration problem, please check the view start date, the period and the priority rules',
		},
	},
	success: {
		production_refresh_completed: 'Production has been successfully refreshed',
		refresh_completed: 'Production has been successfully refreshed',
		update_completed: 'The update of the forecast is finished. Your forecasts will be reloaded',
	},
	info: {
		aggregating_datasets: 'Aggregating datasets',
		preparing_production: 'Transforming data for production',
		preparing_data: 'Transforming data for production',
		exporting_data: 'Exporting data',
		bulk_update_awaiting_update: 'Bulk update done. Awaiting forecast update',
	},
	error: {
		production_refresh_failed: 'Production encountered some errors during refresh',
		refresh_failed: 'Production encountered some errors during refresh',
		no_data:
			'No data was found for production. Please check your configuration.  This may be due to a configuration problem, please check the view start date, the period and the priority rules',
	},
	new_record_title: 'New production',
	actions: {
		create_new: 'Create a production',
		priority_rules: 'Priority rules',
		configuration: 'Configuration',
		back: 'Back to production priority rules',
		back_form: 'Back to production parameters',
		view: 'View',
	},
	settings: {
		title: 'Production settings',
	},
	modals: {
		add_dataset: {
			title: 'Add dataset',
		},
	},
};
