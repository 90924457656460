import React, { Component } from 'react';

import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export default class dateCellRenderer extends Component {
	render() {
		let { value } = this.props;
		if (this.props.value?.value) {
			value = this.props.value.value;
		}
		return (
			<div className="DateCellRenderer">
				<div>{value ? moment(value).tz(moment.tz.guess(), true).format('LLL') : '-'}</div>
				{value && <small>{moment(value).fromNow()}</small>}
			</div>
		);
	}
}
