import _ from 'lodash';
import DataService from 'src/utils/DataService';
import config from 'src/config';
import configWorkflow from 'ui/modules/Core/config/workflow.config';
import { data, getApiPrefix, server } from 'src/utils/api-prefix.helper';
// types
import { IWorkflow } from 'ui/modules/Core/types/interfaces/workflow.interface';
// constants

const serverUrl = `${server}${getApiPrefix()}/`;

/**
 * mapped results of the call apis
 * @param nbCalls
 * @param split
 * @returns
 */
async function getSplitResult(nbCalls: number, split: number): Promise<IWorkflow[]> {
	const res = [];
	for (let i = 1; i <= nbCalls; i++) {
		const url = `${configWorkflow.url}?limit=${split}&offset=${split * i}`;
		const val = new DataService({ url, urlParams: '' });
		res.push(await val.getAll());
	}
	const mappedData: IWorkflow[][] = res.map((el) => el?.data?.result);
	return _.flatMap(mappedData);
}

/**
 * launch api workflow apiCallNumber times
 * @returns
 */
export function getSplittedWorkflow() {
	const split = configWorkflow.split;
	return getWorkflowNewService()
		.getAll()
		.then(
			async ({ data }) => {
				let workflows: IWorkflow[] = [];
				if (data && data.result) {
					workflows = data.result;
					const apiCallNumber = Math.ceil(data.total_size / split) - 1;
					// return result if there is no need to call other api
					if (!apiCallNumber) return workflows;

					// call the api ${apiCallNumber} times to concat all results
					const restWorkflows: IWorkflow[] = await getSplitResult(apiCallNumber, split);
					return [...workflows, ...restWorkflows];
				}
				return workflows;
			},
			(err) => {
				return [];
			}
		);
}

export function getWorkflowNewService() {
	const split = configWorkflow.split || 1000;
	return new DataService({
		url: `${configWorkflow.url}?limit=${split}&offset=${0}`,
		urlParams: '',
		token: true,
	});
}

export function getWorkflowService(params: string) {
	return new DataService({
		url: configWorkflow.oldUrl,
		urlParams: params,
		token: true,
	});
}
export function getOneWorkflow(params: string, workflow_id: string) {
	return new DataService({
		url: `${configWorkflow.url}/${workflow_id}${params}`,
		urlParams: '',
		token: true,
	});
}

export function getPreviewStepService() {
	return new DataService({
		url: `${config.api.clientUrl}/step-preview`,
		urlParams: '',
	});
}

export function loadPreviewService(datasetCode: string, step: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/preview/${datasetCode}/${step}`,
		urlParams: '',
	}).getAll();
}

export function selectPayloadService(datasetCode: string, type: string, date: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${datasetCode}/select-payload/${type}/${date}`,
		urlParams: '',
	});
}

export function loadPendingPayloadService(datasetCode: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/history`,
		urlParams: '',
	});
}

export function loadWorkflowExecutionStateService(datasetCode: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${datasetCode}/execution-state`,
		urlParams: '',
	});
}

export function executeEmailMatchService(datasetCode: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${datasetCode}/email-match/`,
		urlParams: '',
	});
}

export function subscribeWorkflowService(datasetCode: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${datasetCode}/subscribe`,
		urlParams: '',
	});
}

/**
 * getWorkflowSettingService
 * @param newWorkflowID
 * @param datasetCode
 * @returns
 */
export function endWorkflowExecutionService(datasetCode: string, payload: { workflow_id: string }) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/stop`,
		urlParams: '',
	}).create(payload);
}

export function executeWorkflowService() {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/execute`,
		urlParams: '',
	});
}

export function retrieveIssuesService(datasetCode: string, step: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/preview-error/${datasetCode}/${step}`,
		urlParams: '',
	});
}

/**
 * getWorkflowSettingService
 * @param newWorkflowID
 * @returns
 */
export function getWorkflowSettingService(workflowId: string, payload: any = null) {
	if (payload) {
		return new DataService({
			url: `${configWorkflow.ingestWorkflowUrl}/settings/${workflowId}`,
			urlParams: '',
		}).create(payload);
	} else {
		return new DataService({
			url: `${configWorkflow.ingestWorkflowUrl}/settings`,
			urlParams: '',
		}).getClean(workflowId);
	}
}

/**
 * discardDatasetService
 * @param datasetCode
 * @param date
 * @returns
 */
export function discardDatasetService(datasetCode: string, date: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/payloads/${date}/discard`,
		urlParams: '',
	}).deleteAll();
}

/**
 * get workflow preview steps
 * @param datasetCode
 * @param step
 * @param payload
 * @returns
 */
export function getWorkflowPreviewSteps(datasetCode: string, step: string, payload: any) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/steps/${step}/preview`,
		urlParams: '',
	}).create(payload);
}

/**
 * loadHistoryDataService
 * @param payload
 * @returns
 */
export function loadHistoryDataService(payload: any) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflow-loader-history`,
		urlParams: '',
	}).create(payload);
}

/**
 * downloadPayload
 * @param url
 * @returns
 */
export function downloadPayload(url: string) {
	return new DataService({
		url: `${serverUrl}${data}/${url}`,
		urlParams: '',
	}).getAll();
}

/**
 * get workflow script service
 * @param script_id
 * @param path
 * @returns
 */
export const getWorkflowScriptService = async (script_id: string, path: string) => {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/script/${script_id}`,
		urlParams: '',
		token: true,
	}).create({ path });
};

type IExecuteOneEmailBody = {
	email_attachment_id: string;
	email_id: string;
};
/**
 * scheduleWorkflowImmediately
 * @param type
 * @param workflow_id
 * @returns
 */
export function scheduleWorkflowImmediately(type: string, workflow_id: string, body: IExecuteOneEmailBody | null) {
	const url = `${configWorkflow.ingestWorkflowUrl}/${
		type === 'email' ? `workflows/${workflow_id}/replay-last-email` : 'schedule-immediately'
	}`;
	const payload =
		type === 'email'
			? {
					workflow_id,
					...(body || {}),
				}
			: { id: workflow_id };

	return new DataService({ urlParams: [] }).getRequestService().post(url, payload);
}

/**
 * execute all matching workflows (email)
 * @param payload
 * @returns
 */
export const executeAllMatchingWorkflowsService = async (payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/replay-email`,
		urlParams: '',
	}).post(payload);
};

/**
 * execute workflow on attachment
 * @param payload
 * @returns
 */
export const executeWorkflowOnAttachmentService = async (payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/execute-workflow-on-attachment`,
		urlParams: '',
	}).create(payload);
};

/**
 * getWorkflowMetadataService
 * @param workflow_id
 * @returns
 */
export const getWorkflowMetadataService = async (workflow_id: string) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${workflow_id}/metadata`,
		urlParams: '',
	}).getAll();
};

export const saveWorkflowMetaDataService = async (workflow_id: string, payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${workflow_id}/metadata`,
		urlParams: '',
	}).create(payload);
};
