export default {
	name: 'Dashboard',
	name_plural: 'Dashboards',
	other: 'Other',
	other_subgroup: 'Other subgroup',
	group_tab_placeholder: 'Define group tab',
	subgroup_tab_placeholder: 'Define subgroup tab',
	no_iframe_url: 'No iframe source url. Edit this dashboard to add one.',
	no_dashboard_one: 'No dashboard. Click on the',
	no_dashboard_two: 'icon to start adding dashboards',
	invalid_url: 'Invalid url. Edit this dashboard to add a correct one.',
	create_success: 'Dashboard successfully added',
	create_error: 'An error has occured when creating this dashboard',
	edit_success: 'Dashboard successfully edited',
	dashboard_sharing_success: 'Dashboard successfully saved and shared',
	dashboard_sharing_error: 'An error has occured while sharing this dashboard',
	edit_error: 'An error has occured when editing this dashboard',
	delete_confirm: 'Are you sure you want to delete this dashboard ?',
	delete_success: 'Dashboard successfully deleted',
	delete_error: 'An error has occured when deleting this dashboard',
	new_record_title: 'New dashboard',
	labels: {
		send_comment: 'Send comment',
		datasets: 'Datasets',
		description: 'Description',
	},
	actions: {
		add_new: 'Add dashboard',
		edit_dashboard: 'Edit dashboard',
		cancel: 'Cancel',
		save: 'Save',
		delete: 'Delete',
		save_and_share: 'Save and share',
		go_to_workflow: 'Go to workflow',
		close: 'Close',
		add_comment: 'Information',
	},
	confirms: {
		delete_dashboard: 'Are you sure to delete this dashboard? This action cannot be reversed.',
	},
	titles: {
		no_token: 'Unauthorized token',
		no_report_id: 'Report id is missing',
	},
	messages: {
		error_get_dashboards: 'An error has occured when getting dashboards',
		error_get_datasets: 'An error has occured when getting datasets',
		error_get_groups: 'An error has occured when getting groups',
		warning_get_dashboards: 'Sub group not created yet',
		error_token: 'An error has occured when getting token',
		no_description: 'No description',
		no_datasets: 'There are no datasets to be displayed',
		no_dashboards: 'There are no dashboards to be displayed, please add new one',
		success_comment_send: 'Comment sent successfully',
		error_comment_send: 'An error has occured when sending comment',
		invalid_form: 'Invalid Form',
		dashboard_not_found: 'Dashboard not found',
	},
};
