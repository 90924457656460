export default {
	name: 'Advanced search',
	labels: {
		search_placeholder: 'Search term - e.g: Stark Shipping in sources...',
		search_info: 'Advanced search: in all, in name, in content, in reference, in file type...',
	},
	options: {
		all: 'In all',
		name: 'In name',
		content: 'In content',
		date: 'In date',
		date_creation_is: 'Date creation is',
		date_creation_before: 'Date creation before',
		date_creation_after: 'Date creation after',
		date_update_is: 'Date update is',
		date_update_before: 'Date update before',
		date_update_after: 'Date update after',
		year: 'In year',
		year_creation_is: 'Year creation is',
		year_creation_before: 'Year creation before',
		year_creation_after: 'Year creation after',
		year_update_is: 'Year update is',
		year_update_before: 'Year update before',
		year_update_after: 'Year update after',
		month: 'In month',
		month_creation_is: 'Month creation is',
		month_creation_before: 'Month creation before',
		month_creation_after: 'Month creation after',
		month_update_is: 'Month update is',
		month_update_before: 'Month update before',
		month_update_after: 'Month update after',
		file_type: 'In file type',
		reference: 'In reference',
	},
};
