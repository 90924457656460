import _ from 'lodash';
import type { MenuProps } from 'antd';
import { sidebarConfig } from 'ui/components/Nav/Sidenav/config/navItems.config';
import { getItem } from 'ui/components/Nav/Sidenav/helpers/navItem.helper';
import { IPermissions } from 'ui/modules/Core/types/interfaces/user.interface';
import { INavItemProps } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
import { IBusinessCategory } from 'ui/modules/Core/types/interfaces/business-category.interface';
import { faChartArea } from '@fortawesome/pro-light-svg-icons';

type MenuItem = Required<MenuProps>['items'][number];

/**
 * get navItems
 * @param history
 * @param translate
 * @param user
 * @param isAuthenticated
 * @param bookmarks
 * @returns
 */
export const getNavItems = (
	history: any,
	translate: (text: string) => string,
	user: any,
	isAuthenticated: any,
	bookmarks: any,
	myPermissions: IPermissions,
	businessCategories: IBusinessCategory[]
) => {
	const NavItems: MenuItem[] = sidebarConfig.map((item: INavItemProps) => {
		// map business categories under dashboard
		if (item.key === 'dashboard') {
			const children = (businessCategories || [])
				.filter((businessCategory) => businessCategory.dashboard_count > 0)
				.map((businessCategory) => {
					return {
						key: `/dashboard/${businessCategory.code}`,
						description: businessCategory.name,
						path: `/dashboard/${businessCategory.code}`,
						icon: faChartArea,
						is_child: true,
						permissionRecord: ['dashboard'],
					};
				});
			if (children.length) {
				item.children = children;
			}
		}

		// map all sidenav items
		const parentItem = getItem(item, history, translate, user, isAuthenticated, bookmarks, myPermissions) as any;
		if (!item.children || item.key === 'reference_data') return parentItem;
		else {
			const children = parentItem?.children?.[0]?.children;
			const compactChildren = children ? _.compact(children) : undefined;
			if (!parentItem || !parentItem?.children) return null;
			return {
				...parentItem,
				children: [
					{
						...parentItem?.children[0],
						children: compactChildren,
					},
				],
			};
		}
	});

	return _.compact(NavItems);
};

/**
 * get default menuKey
 * @param items
 * @returns
 */
export const getDefaultMenuKey = (items: any) => {
	const currentPath = window.location.pathname;
	let currentItem: any = null;
	_.map(items, (it: any) => {
		// get active parent item
		if (_.startsWith(currentPath, it.path)) {
			currentItem = it;
		}

		// get active childrens
		if (it.children && it.children.length && it.children[0] && it.children[0].children) {
			const child = _.find(it.children[0].children, (children: any) => _.startsWith(currentPath, children.path));
			if (child) {
				currentItem = child;
			}
		}
	});

	return currentItem?.key || [];
};
