// import { uniqueId } from "lodash";
import React, { Component } from 'react';

import { v4 as uuid } from 'uuid';
import { filter } from 'lodash';

import NotificationContext from './NotificationContext';

export default class NotificationProvider extends Component {
	connection = null;

	subscriptions = {};

	subscriptionsType = {};

	loginInterval = null;

	pingInterval = null;

	pushNotification = (component, props = {}) => {
		this.setState({
			notifications: [...this.state.notifications, component],
		});
	};

	popNotification = (number = 1) => {
		this.setState({
			notifications: [...this.state.notifications.slice(0, this.state.notifications.length - number)],
		});
	};

	replaceNotification = (component) => {
		this.setState({
			notifications: [...this.state.notifications.slice(0, this.state.notifications.length - 1), component],
		});
	};

	subscribeToType = (objectType, callback) => {
		if (!this.subscriptionsType[objectType]) this.subscriptionsType[objectType] = [];

		const key = uuid();
		this.subscriptionsType[objectType].push({ id: key, callback });

		return key;
	};

	subscribeToObject = (objectType, objectId, callback) => {
		if (!this.subscriptions[objectType]) this.subscriptions[objectType] = {};
		if (!this.subscriptions[objectType][objectId]) this.subscriptions[objectType][objectId] = [];

		const key = uuid();

		if (this.subscriptions[objectType][objectId].length === 0) {
			this.subscriptions[objectType][objectId].push({ id: key, callback });
		} else {
			return this.subscriptions[objectType][objectId].id;
		}

		return key;
	};

	notifyAllSubscribers = (payload) => {};

	unsubscribeFromObject = (objectType, objectId, key) => {
		// console.log("unsubscribeFromObject", objectType, objectId);
		if (!this.subscriptions[objectType]) this.subscriptions[objectType] = {};
		if (!this.subscriptions[objectType][objectId]) this.subscriptions[objectType][objectId] = [];

		this.subscriptions[objectType][objectId] = filter(
			this.subscriptions[objectType][objectId],
			(v) => v.id !== key
		);
	};

	unsubscribeFromType = (objectType, key) => {
		if (!this.subscriptionsType[objectType]) this.subscriptionsType[objectType] = [];

		this.subscriptionsType[objectType] = filter(this.subscriptionsType[objectType], (v) => v.id !== key);
	};

	state = {
		events: [],
		notifications: [],
		notificationReady: false,
		pushNotification: this.pushNotification,
		popNotification: this.popNotification,
		replaceNotification: this.replaceNotification,
		subscribeToObject: this.subscribeToObject,
		unsubscribeFromObject: this.unsubscribeFromObject,
		subscribeToType: this.subscribeToType,
		unsubscribeFromType: this.unsubscribeFromType,
	};

	componentDidMount() {
		// console.log('NotificationProvider componentDidMount');
		this.connectWithRetries();
	}

	connectWithRetries() {
		if (this.loginInterval) clearInterval(this.loginInterval);

		this.loginInterval = setInterval(() => {
			// console.log("NotificationProvider connectWithRetries - retry");
			this.connect();
		}, 4000);
	}

	connect() {
		// console.log("NotificationProvider connect");
		const token = JSON.parse(localStorage.getItem('token'))?.jwtToken;

		if (typeof token !== 'undefined') {
			this.connection = new WebSocket(`wss://bgj82rj8di.execute-api.eu-west-1.amazonaws.com/dev?token=${token}`);
			this.connection.onopen = this.handleOpen.bind(this);
			this.connection.onmessage = this.handleMessage.bind(this);
			this.connection.onclose = this.handleClose.bind(this);
		}
	}

	handleOpen() {
		// console.log("NotificationProvider handleOpen");
		this.setState({
			notificationReady: true,
		});

		if (this.loginInterval) clearInterval(this.loginInterval);
		if (this.pingInterval) clearInterval(this.pingInterval);

		// Sends a ping every 5 minutes
		this.pingInterval = setInterval(() => {
			try {
				this.connection.send('ping');
			} catch (e) {
				console.warn('ping error', e);
			}
		}, 300000);
	}

	handleMessage(event) {
		// console.log('NotificationProvider handleMessage', event, event.data);

		let payload = null;

		try {
			payload = JSON.parse(event.data);
			// this.setState({ events: [...this.state.events, payload] });
		} catch (e) {
			console.log('Error parsing message', event.data);
			return;
		}

		if (this.subscriptionsType[payload.object_type]) {
			for (let i = 0; i < this.subscriptionsType[payload.object_type].length; i++) {
				this.subscriptionsType[payload.object_type][i].callback(payload);
			}
		}
		if (
			this.subscriptions[payload.object_type] &&
			payload.object_id &&
			this.subscriptions[payload.object_type][payload.object_id]
		) {
			for (let i = 0; i < this.subscriptions[payload.object_type][payload.object_id].length; i++) {
				this.subscriptions[payload.object_type][payload.object_id][i].callback(payload);
			}
		}
	}

	handleClose() {
		// console.log("NotificationProvider handleClose");
		if (this.pingInterval) clearInterval(this.pingInterval);
		setTimeout(() => {
			this.connectWithRetries();
		}, 10);
	}

	render() {
		return <NotificationContext.Provider value={this.state}>{this.props.children}</NotificationContext.Provider>;
	}
}
