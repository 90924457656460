import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';

import '/modules/i18n/i18n';

import './index.sass';
// import { ConfigProvider } from "antd";
// import en_US from "antd/lib/locale/en_US";

if (import.meta.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://32b9133eb8694bfd973e723c18704067@o23207.ingest.sentry.io/5723153',
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.05,
	});
}

ReactDOM.render(
	<Router>
		{/* <ConfigProvider locale={en_US}> */}
		<App />
		{/* </ConfigProvider> */}
	</Router>,

	document.getElementById('root')
);
