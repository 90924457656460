import models from './models';
import form from './form';
import view from './view';
import actions from './actions';
import operations from './operations';
import messages from './messages';
import usage from './usage';

export default {
	name: 'Pipeline',
	form,
	view,
	actions,
	models,
	operations,
	messages,
	usage,
};
