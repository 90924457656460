export default {
	tabs: {},
	sections: {},
	contract_between: 'Contract between {{contractee}} and {{organization}}',
	contractee: 'Contractee',
	fields: {
		status: {
			label: 'Status',
			placeholder: 'Select a status',
		},
		start_at: {
			label: 'Start at',
			placeholder: 'Select a start date',
		},
		end_at: {
			label: 'End at',
			placeholder: 'Select an end date',
		},
		amend_name: {
			label: 'Amend name',
			placeholder: '',
		},
		expire_in: {
			label: 'Expire in',
			placeholder: 'Select an expiration date',
		},
		expired: {
			label: 'Expired',
		},
		ago: {
			label: 'ago',
		},
		date_creation_of_the_contract: {
			label: 'Date of creation of the contract',
			placeholder: 'Select a date of creation of the contract',
		},
		created_at: {
			label: 'Created at',
			placeholder: 'Select a creation date',
		},
		updated_at: {
			label: 'Updated at',
			placeholder: 'Select a creation date',
		},
		creator_email: {
			label: 'Creator email',
			placeholder: 'Select a creator email',
		},
		updater_email: {
			label: 'Updated by',
			placeholder: '',
		},
		resource_groups: {
			label: 'Resource Group',
			placeholder: 'Select a resource group',
			empty: 'No resource group',
		},
		resources: {
			label: 'Resources',
			placeholder: 'Select a resource',
			empty: 'No resource',
		},
		resource_type: {
			label: 'Resource type',
			placeholder: 'Select a resource type',
			empty: 'No resource type',
		},
		amend: {
			label: 'Amend',
			placeholder: 'Select an amend',
		},
		usage: {
			label: 'Usage',
			placeholder: 'Select a usage',
			empty: 'No usage',
		},
		name: {
			label: 'Name',
			placeholder: 'Enter a name',
		},
		description: {
			label: 'Description',
			placeholder: 'Enter a description',
		},
	},
	labels: {
		summary: 'Summary',
		publishing: 'Publishing',
	},
	warnings: {
		no_resource_id: 'This record have not a valid resource id',
	},
};
