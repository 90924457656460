import React from 'react';

import './Loader.sass';

export default function Loader(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.size || 120}
			height={props.size || 120}
			style={{ margin: 'auto', display: 'flex' }}
			viewBox="0 0 605.89 625.69"
			className="Loader"
		>
			<defs />
			<g fill="none" fillRule="evenodd" transform="translate(1 1)">
				<path
					className="cls-1"
					d="M498,383.49c0,126.07-102.21,228.39-228.28,228.39a228.22,228.22,0,0,1-175.6-82.29A226.82,226.82,0,0,0,240.9,583.17c126.19,0,228.39-102.32,228.39-228.39a228.1,228.1,0,0,0-53.13-146.55A228.35,228.35,0,0,1,498,383.49Z"
				/>
				<path
					className="cls-2"
					d="M233.53,522.94C107.46,522.94,5.14,420.73,5.14,294.66a228.22,228.22,0,0,1,82.29-175.6A226.84,226.84,0,0,0,33.85,265.85c0,126.18,102.32,228.39,228.39,228.39a228.12,228.12,0,0,0,146.55-53.13A228.38,228.38,0,0,1,233.53,522.94Z"
				/>
				<path
					className="cls-1"
					d="M109.91,258.52c0-126.07,102.21-228.39,228.28-228.39a228.2,228.2,0,0,1,175.59,82.28A226.82,226.82,0,0,0,367,58.83c-126.18,0-228.39,102.32-228.39,228.39a228.12,228.12,0,0,0,53.13,146.56A228.38,228.38,0,0,1,109.91,258.52Z"
				/>
				<path
					className="cls-2"
					d="M374.33,119.06c126.07,0,228.39,102.21,228.39,228.28a228.22,228.22,0,0,1-82.28,175.6A226.85,226.85,0,0,0,574,376.16C574,250,471.7,147.77,345.63,147.77A228.12,228.12,0,0,0,199.07,200.9,228.38,228.38,0,0,1,374.33,119.06Z"
				/>
			</g>
		</svg>
	);
}
