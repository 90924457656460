import React from 'react';

import './TopNav.scss';

class TopNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nocView: false,
		};
	}

	render() {
		// A faire DarkMode +

		/*
        return(


            <div className="switch">
              <FontAwesomeIcon icon={faMoon} />
                <input type="checkbox" checked="" onChange={e => {}} />

                <span className="slider round"></span>
            </div>


        ); */
		return '';
	}
}

export default TopNav;
