import { faShip } from '@fortawesome/pro-light-svg-icons';
import { basePath, server } from 'src/utils/api-prefix.helper';
import { getForecastService } from 'ui/modules/Fundamentals/Forecasts/services/forecast.service';
const baseApiUrl = `${server}${basePath}/`;
export default {
	referenceData: false,
	iconName: faShip,
	url: `${baseApiUrl}forecast`,
	getService: getForecastService,
	urlParams: 'relation=[forecast_version]',
	path: '/fundamentals/forecasts',
	parentPage: {
		name: 'Fundamentals',
	},
	pageTitle: {
		list: 'Forecasts',
		action: {
			create: 'New forecast',
			edit: 'Edit forecast',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Shared',
			field: 'shared',
			type: 'boolean',
			minWidth: 100,
			width: 100,
		},
		{
			headerName: 'Created',
			field: 'createdAt',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updatedAt',
			type: 'datetime',
		},
		{
			headerName: 'Last finalized version at',
			field: 'finalized_at',
			type: 'datetime',
		},
		{
			headerName: 'Last updated version at',
			field: 'last_update_at',
			type: 'datetime',
		},
	],
	formatDataFromList: (data: any) =>
		data.map((d: any) => {
			let lastFinalizedVersion = null;
			let lastUpdateAt = null;
			if (d.forecast_version) {
				const finalizedList = d.forecast_version.filter((f: any) => f.finalized_at !== null);
				lastFinalizedVersion = finalizedList.sort((a: any, b: any) =>
					a.finalized_at < b.finalized_at ? 1 : a.finalized_at > b.finalized_at ? -1 : 0
				)[0];

				const updatedList = d.forecast_version.filter((f: any) => f.last_update_at !== null);
				lastUpdateAt = updatedList.sort((a: any, b: any) =>
					a.last_update_at < b.last_update_at ? 1 : a.last_update_at > b.last_update_at ? -1 : 0
				)[0];
			}
			return {
				...d,
				finalized_at: lastFinalizedVersion ? lastFinalizedVersion.finalized_at : null,
				last_update_at: lastUpdateAt ? lastUpdateAt.last_update_at : null,
			};
		}),
	canCreate: false,
	canEdit: true,
	canDelete: true,
	canArchive: false,
	canbeCloned: false,
	hasShared: true,
	navigateByCode: true,
};
