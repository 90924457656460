import { DataService } from 'src/utils';
import { saveAs } from 'file-saver';
import Axios from 'axios';

import { fundamentals, getApiPrefix, server } from 'src/utils/api-prefix.helper';

import { uniqBy } from 'lodash';

// To be remove after removing old sharing
import config from 'config';

export const getForecastService = (version = true) => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/forecasts`,
		urlParams: version ? { forecast_version: true } : '',
		token: true,
	});
};

export const deleteForecast = (id: string) => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/forecasts`,
		urlParams: '',
		token: true,
	}).delete(id);
};

export const getForecastByType = async (type: string, formated = false) => {
	const result = await new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/forecasts`,
		urlParams: '',
		token: true,
	}).getAll();
	const filtedResult = result.data.result.filter((c: any) => c.type === type);
	if (!formated) return filtedResult;
	return formatDataForTransfer(filtedResult);
};

export const getOneForecastService = (code: string) => {
	return new DataService({
		url: `${server}${getApiPrefix()}/${fundamentals}/forecasts/${code}`,
		urlParams: '',
		token: true,
	}).getAll();
};

export const getForecastDataByCode = async (
	code: string,
	type: string,
	refresh: boolean,
	start_date: string,
	end_date: string,
	start_month: string,
	version: string
) => {
	try {
		const result = await new DataService({
			url: `${server}${getApiPrefix()}/${fundamentals}/forecasts/${type}/${code}/get`,
			urlParams: '',
		}).create({
			minForecastDate: start_date,
			maxForecastDate: end_date,
			startMonth: start_month,
			useCache: refresh,
			version: version,
		});

		return result;
	} catch (err) {
		console.log('err', err);
		return null;
	}
};

export const downloadForecast = async (code: string, type: string) => {
	try {
		const result = await new DataService({
			url: `${server}${getApiPrefix()}/${fundamentals}/forecasts/${type}/${code}/download`,
			urlParams: '',
			token: true,
		}).getAll();
		const fileUrl = result.data.result.url;
		if (fileUrl) {
			const file = await Axios.get(fileUrl, {
				responseType: 'blob', // Important
			});
			const blob = file.data;
			saveAs(blob, `${code}_${new Date().getTime()}.csv`);
			return true;
		}
	} catch (err) {
		return null;
	}
};

/**
 * @TODO To be remove after removing old sharing
 */
export const getForecastsOld = async (id: string) => {
	const result = await new DataService({
		url: `${config.api.baseUrl}forecast`,
		urlParams: '?relation=[data_sharing]',
		token: true,
	}).get(id);

	result.data.data_sharing = result.data.data_sharing.map((c: any) => {
		c.end_at = null;
		c.start_at = null;
		return c;
	});
	return result.data;
};

/**
 * @TODO To be remove after removing old sharing
 */
export const getAvailableForecasts = async (id: string, selectFormat = false) => {
	const tfs = await Promise.all([
		getForecastService().getAllWithParamObj(),
		new DataService({
			url: `${config.api.baseUrl}data_sharing`,
			token: true,
			urlParams: '?relation=[forecast]',
		}).getAll(),
	]);
	const data: string[] = [];
	tfs.map((x, i) => {
		if (i === 1)
			data.push(
				x.data.map((c: any) => {
					return { ...c.forecast, shared: 1 };
				})
			);
		else data.push(x.data.result.filter((c: any) => c.id !== id));
	});

	if (selectFormat) {
		return uniqBy(data.flat(), 'code').map((el: any) => ({
			id: el.id,
			key: el.id,
			title: el.name,
			description: el.name,
			code: el.code,
			type: el.type,
			parent: el.parent ? el.parent.name : null,
		}));
	}
	return uniqBy(data.flat(), 'code');
};

//To be moved somewhere else
const formatDataForTransfer = (data: string[]) => {
	return data.map((el: any, i) => ({
		id: el.id,
		parent: el.parent ? el.parent.name : null,
		code: el.code,
		priority: i,
		key: el.id,
		name: el.name,
		title: el.name,
		type: el.type,
		description: el.name,
	}));
};
