export default {
	info: {
		reference_sync_progress: 'Reference list sync is still in progress',
		reference_replacement_failed: 'Reference replacement ended with errors',
	},
	success: {
		reference_sync_ended: 'Reference list sync ended successfully',
		reference_replacement_ended: 'Reference replacement ended successfully',
	},
};
