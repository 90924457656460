// get refs from local storage
export const getRefsFromCache = (key: string) => {
	const res = localStorage.getItem(key);
	return res ? JSON.parse(res) : null;
};

// set data to local storage
export const setRefsInCache = (key: string, data: any) => {
	return localStorage.setItem(key, JSON.stringify(data));
};
