export default {
	tabs: {
		general: 'General',
		history: 'History',
		sources: 'Sources',
		report: 'Report',
		execution: 'Execution',
		results: 'Results',
	},
	sections: {
		general: 'General',
	},
};
