// service
import DataService from 'src/utils/DataService';
// config
import config from 'src/config';
import { flatMap } from 'lodash';
// constants
const { referenceDataUrl, elasticSearchUrl } = config.api;

/**
 *
 * @returns
 */
export const getReferences = async () => {
	const result = await new DataService({
		url: `${config.api.baseUrl}reference-list`,
		urlParams: '?relation=[reference_list_field]',
		token: true,
	}).getAll();

	return result.data && Array.isArray(result.data) ? result.data : [];
};

/**
 * Get splitted reference by limit 3000
 * @param recordType
 * @param nbCalls
 * @param split
 * @param params
 * @returns
 */
async function getSplitReferencesResult(recordType: string, nbCalls: number, split: number, params: string) {
	const res = [];
	for (let i = 1; i <= nbCalls; i++) {
		const url = `${referenceDataUrl}${recordType}?limit=${split}&offset=${split * i}${params}`;
		const val = new DataService({ url, urlParams: '' });
		res.push(await val.getAll());
	}
	const mappedData = res.map((el) => el?.data?.result);
	return flatMap(mappedData);
}

export function getReferenceDataByType(currConfig: any) {
	const recordType = currConfig?.groupOf ? currConfig.groupOf : currConfig?.referenceRecord;
	const params = currConfig?.groupOf ? '&params=is_group' : currConfig?.is_mixed ? '&params=is_mixed' : '';
	const split = 3000;
	return new DataService({
		url: `${referenceDataUrl}${recordType}?limit=${split}&offset=${0}${params}`,
		urlParams: '',
		token: true,
	})
		.getAll()
		.then(
			async ({ data }) => {
				let references: any = [];
				if (data && data.result) {
					references = data.result;
					const total_size = data.total_size || 6000; // must return total_size in the api
					const apiCallNumber = Math.ceil(total_size / split) - 1;
					// return result if there is no need to call other api
					if (!apiCallNumber) return references;
					// call the api ${apiCallNumber} times to concat all results
					const resData = await getSplitReferencesResult(recordType, apiCallNumber, split, params);
					return [...references, ...resData];
				}
				return references;
			},
			(err) => {
				return [];
			}
		);
}

/**
 * get one reference
 * @param recordType
 * @param id
 * @param params
 * @returns
 */
export function getOneReferenceByType(recordType: string, id: string, params: string = '') {
	return new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: params,
	}).getClean(id);
}

/**
 * getRelatedDatasets
 * @param recordType
 * @param code
 * @returns
 */
export async function getRelatedDatasets(recordType: string, code: string) {
	const payload = {
		searchTerm: null,
		filters: [{ type: recordType, key: code }],
		sortDirection: 'asc',
		from: 0,
		size: 1000,
	};
	return new DataService({
		url: `${elasticSearchUrl}`,
		params: '',
		token: true,
	}).post(payload);
}

/**
 * create reference
 * @param recordType
 * @param payload
 * @returns
 */
export const createReference = async (recordType: string, payload: any) => {
	return await new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: '',
		token: true,
	}).create(payload);
};

/**
 * update reference
 * @param recordType
 * @param code
 * @param payload
 * @returns
 */
export const updateReference = async (recordType: string, code: string, payload: any) => {
	return await new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: '',
		token: true,
	}).update(code, payload);
};

/**
 *
 * @param recordType
 * @param code
 * @returns
 */
export const deleteReferenceByCode = async (recordType: string, code: string) => {
	return await new DataService({
		url: `${referenceDataUrl}${recordType}`,
		urlParams: '',
		token: true,
	}).delete(code);
};

/**
 * bulk delete reference
 * @param recordType
 * @param payload
 * @returns
 */
export function bulkDeleteReferencesService(recordType: string, payload: { codes: string[] }) {
	return new DataService({
		url: `${referenceDataUrl}${recordType}/delete`,
		urlParams: '',
	}).create(payload);
}
