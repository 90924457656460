export enum MimeType {
	'SHELL' = 'application/x-sh',
	'JSON' = 'application/json',
	'EML' = '.eml',
	'PBIX' = '.pbix',
	'PDF' = 'application/pdf',
	'PNG' = 'image/png',
	'JPG' = 'image/jpg',
	'JPEG' = 'image/jpeg',
	'CSV' = 'text/csv',
	'XLS' = 'application/vnd.ms-excel',
	'XLSX' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'PPT' = 'application/vnd.ms-powerpoint',
	'PPTX' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'DOC' = 'application/msword',
	'DOCX' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

enum FileType {
	'FOLDER' = 'Files folder',
	'application/x-sh' = 'SHELL',
	'application/json' = 'JSON',
	'application/pdf' = 'PDF',
	'image/png' = 'PNG',
	'image/jpg' = 'JPG',
	'image/jpeg' = 'JPEG',
	'text/csv' = 'CSV',
	'application/vnd.ms-excel' = 'XLS',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'XLSX',
	'application/vnd.ms-powerpoint' = 'PPT',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'PPTX',
	'application/msword' = 'DOC',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'DOCX',
	'.eml' = 'EML',
	'.pbix' = 'PBIX',
	'PDF' = 'PDF',
	'PNG' = 'PNG',
	'JPG' = 'JPG',
	'JPEG' = 'JPEG',
	'CSV' = 'CSV',
	'XLS' = 'XLS',
	'XLSX' = 'XLSX',
	'PPT' = 'PPT',
	'PPTX' = 'PPTX',
	'DOC' = 'DOC',
	'DOCX' = 'DOCX',
	'EML' = 'EML',
	'PBIX' = 'PBIX',
	'JSON' = 'JSON',
	'SHELL' = 'SHELL',
}
export default FileType;
