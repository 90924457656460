export default {
	error: {
		column_name_doest_not_exist_in: 'Column name does not exist in step: {step}',
		not_exists_or_not_authorized: "You don't have access to this input or it doesn't exist",
		general: 'Something went wrong',
		run: 'Error during process of dataprep run, please review your steps and try again',
		preparing_failed: 'Error during process of dataprep run, please review your steps and try again',
		dataprep_failed: 'Error during process of dataprep run, please review your steps and try again',
		creation: 'Error during process of pipeline creation, please review your steps and try again',
		error_trying_reaching_resource: 'The outcome cannot be executed due to an error during execution of the task',
		cannot_retrieve_file_or_file_not_found: 'Cannot retrieve file or file may be empty',
		no_operation_found: 'No operation found',
		no_outcome: 'No outcome found',
	},
	success: {
		general: 'Wunderbar!',
		dataprep_success: 'Data successfully prepared',
		creation: 'Pipeline successfully created',
	},
	info: {
		preparing_data: 'Preparing data...',
	},
	preview: 'Preview',
};
