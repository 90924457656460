export const recordType = 'email';

export const emailColumns = [
	{
		headerName: 'Sender',
		field: 'from',
		type: 'email',
		sortable: true,
		flex: 1,
	},
	{
		headerName: 'Subject',
		field: 'subject',
		sortable: true,
		flex: 1,
	},
	{
		headerName: 'Received',
		field: 'received_at',
		type: 'datetime',
		sortable: true,
		flex: 1,
	},
];
