import React from 'react';
//Ant design & Icons
import { faTruck } from '@fortawesome/pro-light-svg-icons';
//utils
import { server, basePath } from 'src/utils/api-prefix.helper';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';
// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceRecord: 'transport-type',
	referenceData: true,
	navigateByCode: true,
	iconName: faTruck,
	url: `${baseApiUrl}transport-type`,
	urlParams: 'relation=[user,transport_category,direct_parent]',
	elasticSearchKey: 'TransportType',
	relatedReferences: ['transport-category'],
	keysMatching: [{ key: 'transport_category_id', type: 'transport-category' }],
	path: '/transport-types',
	pageTitle: {
		list: 'Transport types',
		action: {
			create: 'New transport type',
			edit: 'Edit transport type',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			cellRendererFramework: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/transport-types/${data.direct_parent.code}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'Transport Category',
			field: 'transport_category_id',
			type: 'reference',
			referenceType: 'transport_category',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	parentField: 'parent_id',
};
