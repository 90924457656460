export const recordType = 'email_ruleset';

export const emailRulesetColumns = [
	{
		headerName: 'Name',
		field: 'name',
		minWidth: 220,
		width: 400,
		flex: 1,
	},
	{
		headerName: 'Created at',
		field: 'createdAt',
		type: 'datetime',
		minWidth: 200,
		flex: 1,
	},
	{
		headerName: 'Updated at',
		field: 'updatedAt',
		type: 'datetime',
		minWidth: 200,
		flex: 1,
	},
];
