import DataService from 'src/utils/DataService';
import labelConfig from 'src/ui/modules/Core/config/label.config';

/**
 * get label list
 * @returns
 */
export function getLabelsService(resourceType: string | null = null) {
	const params = resourceType ? `?type=${resourceType === 'workflow' ? 'dataset' : resourceType}` : '';
	return new DataService({
		url: `${labelConfig.url}${params}`,
		urlParams: '',
		token: true,
	});
}

/**
 * get label details
 * @param id
 * @returns
 */
export const getLabel = async (id: string) => {
	const result = await new DataService({
		url: labelConfig.url,
		urlParams: '',
	}).getClean(id);
	return result.data.result;
};

/**
 * create label
 * @param payload
 * @returns
 */
export const createLabel = async (payload: any) => {
	return await new DataService({
		url: `${labelConfig.url}`,
		urlParams: '',
	}).create(payload);
};

/**
 * update label
 * @param id
 * @param payload
 * @returns
 */
export const updateLabel = async (id: string, payload: any) => {
	return await new DataService({
		url: labelConfig.url,
		urlParams: '',
	}).update(id, payload);
};

/**
 * apply labels By Resources "dataset" | "tradeflow" | "workflow"
 * @param payload
 * @returns
 */
export const applyTagsByResources = async (payload: {
	resourceType: 'dataset' | 'tradeflow' | 'workflow' | 'resource_group';
	resource_ids: string[];
	label_ids: string[];
}) => {
	const { resourceType, ...dataToSend } = payload;
	return await new DataService({
		url: `${labelConfig.api.applyLabelToResources}${resourceType === 'workflow' ? 'dataset' : resourceType}`,
		urlParams: '',
	}).post(dataToSend);
};
