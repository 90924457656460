import React, { Component } from 'react';

export default class booleanRenderer extends Component {
	render() {
		if (this.props.value == null) return null;
		return `${this.props.value}` === 'true' || `${this.props.value}` == 1 ? (
			<span className="ag-icon ag-icon-tick" />
		) : `${this.props.value}` === 'false' || `${this.props.value}` == 0 ? (
			<span className="ag-icon ag-icon-cross" />
		) : (
			'Select All'
		);
	}
}
