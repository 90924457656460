import React from 'react';

import { Modal, Button } from 'react-bootstrap';

/**
 * Tableau pour l'affichage des martelages
 */
export class ConfirmDialog extends React.Component {
	onConfirmDialogClick(id) {
		this.props.onConfirmDialogClick(id);
	}

	render() {
		return (
			<Modal show={this.props.open} onHide={this.onConfirmDialogClick.bind(this, false)}>
				<Modal.Header closeButton>
					<Modal.Title>{this.props.text}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{this.props.body}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.onConfirmDialogClick.bind(this, false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={this.onConfirmDialogClick.bind(this, true)}>
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ConfirmDialog;
