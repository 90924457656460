import models from './models';
import form from './form';
import view from './view';
import messages from './messages';
import actions from './actions';
import settings from './settings';
import modals from './modals';
import panel from './panel';

export default {
	name: 'Fundamentals',
	form,
	view,
	models,
	messages,
	actions,
	settings,
	modals,
	panel,
};
