export default {
	dashboard: 'Dashboard',
	mapping: {
		title: 'Mapping',
		global_reference_mapping: 'Global reference mapping',
		dataset_reference_mapping: 'Dataset reference mapping',
	},
	storage: 'Storage',
	reference_data: 'Reference data',
	report: 'Reporting',
	coming_soon: 'Coming soon',
	twitter: 'Twitter',
	user_profile: 'User Profile',
	support: 'Support',
	logout: 'Logout',
};
