import React, { Component } from 'react';

export default class emailRenderer extends Component {
	render() {
		if (this.props.value == null) return null;

		return (
			<span>
				<a target="_blank" rel="noopener noreferrer" href={this.props.value}>
					{this.props.value}
				</a>
			</span>
		);
	}
}
