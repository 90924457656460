import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { IUserRoles, IUserColumnConfig } from 'src/ui/modules/Core/types/interfaces/user.interface';
import _ from 'lodash';

/**
 * get permission state
 * @param event
 * @returns
 */
export const getState = (event: any) => {
	const data = event.value;
	if (_.isNil(data)) {
		return (
			<div className="d-flex justify-content-center">
				<FontAwesomeIcon style={{ color: '#ccc', fontSize: 10 }} icon={faMinus} />
				{/* faEmptySet */}
			</div>
		);
	}
	return (
		<div className="d-flex justify-content-center">
			<FontAwesomeIcon
				style={{ color: data ? '#4f994d' : '#ff4d4f', fontSize: 16 }}
				icon={data ? faCheck : faTimes}
			/>
		</div>
	);
};

// get Module
const getModule = (event: any) => {
	const data = event.value;
	return <div>{_.upperFirst(_.toLower(_.replace(data, '_', ' ')))}</div>;
};
export const columnDefs = [{ field: 'module', cellRendererFramework: getModule, minWidth: 150, flex: 1 }];

export const recordType = 'user';
export const dropboxAuthUrl = 'https://api.dnext.io/connectors/dropbox/auth-url';
export const microsoftAuthUrl = 'https://api.dnext.io/connectors/microsoft/auth-url';
export const twitterAuthUrl = 'https://api.dnext.io/connectors/twitter/auth-url';

export const userRoles: IUserRoles = {
	user: ['commonColumns'],
	admin: ['commonColumns', 'adminColumns'],
	// Define other roles and their hierarchy here
};

export const userColumnConfig: IUserColumnConfig = {
	commonColumns: [
		{
			headerName: 'Name',
			field: 'first_name',
		},
		{
			headerName: 'Family name',
			field: 'last_name',
		},
	],
	adminColumns: [
		{
			headerName: 'Active',
			field: 'enabled',
			type: 'boolean',
		},
		{
			headerName: 'Email',
			field: 'email',
			type: 'email',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
		{
			headerName: 'Roles',
			field: 'roles',
			type: 'tags',
		},
		{
			headerName: 'Permissions',
			field: 'permissions',
			type: 'permissions',
		},
	],
};
