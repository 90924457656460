import modules from 'locales/modules/en';
import pages from './pages';
import form from './form.json';
import view from './view';
import wizard from './wizard.json';
import list from './list.json';
import actions from './actions';
import misc from './misc';
import navigation from './navigation';
import messages from './messages';
import model from './model';
import system from './system';
import settings from './settings';
import notification from './notification';

export default {
	pages,
	form,
	view,
	wizard,
	navigation,
	actions,
	misc,
	list,
	messages,
	model,
	system,
	settings,
	modules,
	notification,
};
