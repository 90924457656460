import { getApiPrefix, server } from 'src/utils/api-prefix.helper';

export const AuthorizedOrgWithMFA = ['dndev', 'dnexr'];

export default {
	path: '/login',
	api: {
		retrieveOrgsOld: `${server}services/retrieveAccessOrganization`,
		retrieveOrgs: `${server}${getApiPrefix()}/auth/access-org`,
		login: `${server}${getApiPrefix()}/auth/login`,
		customLogin: `${server}${getApiPrefix()}/auth/custom-login`,
		challenge: `${server}${getApiPrefix()}/auth/login/challenge`,
		changePassword: `${server}${getApiPrefix()}/auth/change-password`,
	},
};
