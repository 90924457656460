import config from 'config';
import { faAbacus } from '@fortawesome/pro-light-svg-icons';

import { downloadFile } from 'ui/modules/Pipeline/utils/service.dataprep';

config.fields = {
	trade_flows: [
		{ name: 'Date Reporter', type: 'date' },
		{ name: 'Partner', type: 'text' },
		{ name: 'Reporter_MappedCode', type: 'text' },
		{ name: 'Product', type: 'text' },
		{ name: 'CropYear', type: 'text' },
		{ name: 'Month', type: 'text' },
		{ name: 'MonthYear', type: 'text' },
		{ name: 'Year', type: 'numeric' },
		{ name: 'Forecast', type: 'boolean' },
		{ name: 'Quantity', type: 'numeric' },
		{ name: 'Product_Name', type: 'text' },
		{ name: 'Reporter_MappedName', type: 'text' },
		{ name: 'Partner_Name', type: 'text' },
		{ name: 'Group_Reporter', type: 'text' },
		{ name: 'Group_Partner', type: 'text' },
	],
	forecasts: [
		{ name: 'Date', type: 'date' },
		{ name: 'Destination', type: 'Location' },
		{ name: 'Origin', type: 'Location' },
		{ name: 'Product', type: 'Product' },
		{ name: 'Quantity', type: 'numeric' },
		{ name: 'Version', type: 'numeric' },
	],
};
config.pipeline = {
	referenceData: false,
	iconName: faAbacus,
	url: `${config.modules.crud}/pipeline`,
	recordid: 'id', // required for duplication !
	urlParams: '',
	path: '/data-prep/pipeline',
	parentPage: {
		name: 'data-prep',
	},
	onReceiveNotification(event) {
		console.log('custom onReceiveNotification', event);

		if (event.code === 'preparing_data')
			this._alterRowData(event.object_id, {
				last_status: 'running',
			});

		if (event.details?.direct && event.details?.task_id && event.details?.url) {
			downloadFile(event.details?.url, event.details?.task_id);
		}

		if (event.type === 'error' || event.type === 'success') {
			const newStatus = event.type;

			this._alterRowData(
				event.object_id,
				{
					last_status: newStatus,
				},
				null,
				'code'
			);
		}

		this.pushNotification(this.props.t(`modules.pipeline.messages.${event.type}.${event.code}`), event.type, null);
	},
	pageTitle: {
		list: 'Pipeline',
		action: {
			create: 'Pipeline',
			edit: 'Edit Pipeline',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Code',
			field: 'code',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Tag',
			field: 'tag',
			type: 'label',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Status',
			field: 'last_status',
			type: 'tag',
			typeParams: {
				mapping: {
					success: 'green',
					error: 'red',
					ready: 'purple',
				},
				null_value: 'Not yet run',
			},
			minWidth: 90,
			values: ['success', 'error', 'under_edit', 'ready', 'running'],
		},
		{
			headerName: 'Last run at',
			field: 'last_run_at',
			type: 'datetime',
			minWidth: 200,
			description: 'Last execution date of the workflow',
		},
		{
			headerName: 'Updated At',
			field: 'updatedAt',
			type: 'datetime',
			minWidth: 200,
		},
		{
			headerName: 'Created At',
			field: 'createdAt',
			type: 'datetime',
			minWidth: 200,
		},
	],
	canView: true,
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canBulkExecute: false,
	useTab: 'general',
	canExecuteDataPrep: true,
};

config.dataprep = {
	url: `${config.api.dataPrep}`, // `${config.modules.crud}/dataprep`,
};

export default config;
