import React, { Component } from 'react';

import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export default class firstEntryFromListRenderer extends Component {
	render() {
		const { value } = this.props;
		const type = this.props.colDef.typeofentry;

		if (type === 'datetime') {
			return (
				<div className="DateCellRenderer">
					<div>{value ? moment(value).tz(moment.tz.guess()).format('LLL') : '-'}</div>
					{value && <small>{moment(value).fromNow()}</small>}
				</div>
			);
		}

		return value;
	}
}
