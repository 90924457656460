import NotificationDetails from 'ui/components/Modal/NotificationDetails';

const acceptedTypes = ['success', 'warning', 'error', 'info'];

export const pushNotification = (addToast, title = '', type = 'info', details = '', autoDismiss = true) => {
	if (acceptedTypes.includes(type) === false)
		return console.error(`pushNotification: type must be one of ${acceptedTypes.join(', ')}`);

	if (addToast) {
		addToast(<NotificationDetails title={title}>{details}</NotificationDetails>, {
			appearance: type,
			autoDismiss: type !== 'error' || autoDismiss,
		});
	}
};
