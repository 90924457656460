import React, { Component } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import DataService from 'utils/DataService';
import config from 'config';

export default class stateRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingParameter: false,
			value: this.props.value,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.value !== nextProps.value) {
			this.setState({ value: nextProps.value });
		}
	}

	async handleChangeState(value, wfId) {
		this.setState({ loadingParameter: true, value: value.key }, async () => {
			await new DataService({
				url: config.records.workflow.url,
				urlParams: '?relation=[dataset]',
				token: true,
			}).update(wfId, {
				state: value.key,
			});
			this.setState({ loadingParameter: false });
		});
	}

	render() {
		const value = this.state.value || this.props.null_value;
		const wfId = this.props.data?.id;

		if (!value) return null;

		const { icons } = this.props;
		if (!wfId) {
			if (icons[value]) return <>{icons[value]}</>;
			return value;
		}

		const menu = (
			<Menu onClick={(key) => this.handleChangeState(key, wfId)}>
				{Object.keys(icons).map((o) => (
					<Menu.Item key={o} icon={icons[o]} style={{ margin: '3px  0px' }} />
				))}
			</Menu>
		);

		// const color = mapping ? mapping[value] : this.props.color; // default color is no-color = grey

		return (
			<Dropdown overlay={menu} trigger={['click']}>
				<Button
					style={{ color: '#222222', width: '24px', height: '24px', lineHeight: '5px' }}
					loading={this.state.loadingParameter}
					size="small"
					onClick={(e) => e.preventDefault()}
					icon={icons[value] || '...'}
				/>
			</Dropdown>
		);
	}
}
