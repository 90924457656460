import React, { Component } from 'react';
import { Popover, Button, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBurgerSoda, faTable } from '@fortawesome/pro-light-svg-icons';

export default class RenderColumnPopOver extends Component {
	constructor(props) {
		super(props);

		this.state = {
			date: null,
			cols: this.props.cols,
		};
		this.banKey = ['actions', 'checkbox'];
	}

	componentDidMount() {
		const listColumns = this.props.cols;
		let columns = [];

		columns = this.defineCols(listColumns);

		this.setState({ cols: columns });
	}

	componentDidUpdate(prevProps) {
		if (this.props.axKey !== prevProps.axKey) {
			const listColumns = this.props.cols;
			let columns = [];

			columns = this.defineCols(listColumns);

			this.setState({ cols: columns });
		}
	}

	defineCols(listColumns) {
		if (!Array.isArray(listColumns)) return [];

		const columns = [];
		for (let i = 0; i < listColumns.length; i++) {
			const el = listColumns[i];
			if (this.banKey.includes(el.colId) || !el.userProvidedColDef) continue;
			columns.push({ name: el.userProvidedColDef?.headerName, visible: el.visible, id: el.colId });
		}
		return columns;
	}

	filterCols(e) {
		const { value } = e;

		if (value.length < 1) {
			this.setState({ cols: this.defineCols(this.props.cols) });
			return;
		}

		const filteredCols = this.state.cols.filter(
			(x) => x.name && x.name.toLowerCase().includes(value.toLowerCase())
		);

		this.setState({ cols: filteredCols });
	}

	renderListItem(col, i, all = false) {
		return (
			<div
				key={`${col.name}aq${i}`}
				className={`mt-1 ag-column-select-column ag-column-select-indent-${i}`}
				aria-hidden="true"
				onClick={() => this.props.toggleVisibleColumn(col.id, col.visible, all)}
			>
				<div
					role="presentation"
					ref="cbSelect"
					className="ag-column-select-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
				>
					<div
						ref="eLabel"
						className="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
						role="presentation"
					/>
					<div
						ref="eWrapper"
						className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${
							col.visible ? 'ag-checked' : ''
						}`}
						role="presentation"
					>
						<input ref="eInput" className="ml-2 ag-input-field-input ag-checkbox-input" type="checkbox" />
					</div>
				</div>
				<span
					className="ag-icon ag-icon-grip ag-drag-handle ag-column-select-column-drag-handle ag-hidden"
					unselectable="on"
					role="presentation"
				/>
				<span className="ag-column-select-column-label ml-3" ref="eLabel">
					{col.name}
				</span>
			</div>
		);
	}

	render() {
		let content = null;
		const columns = this.state.cols;

		if (!Array.isArray(columns)) return;

		content = (
			<div
				className="ag-theme-balham"
				style={{ maxHeight: '300px', overflowY: 'scroll', overflowX: 'hidden', width: '300px' }}
			>
				<Input placeholder="Search" className="mb-2" onChange={(e) => this.filterCols(e.target)} />
				{this.renderListItem(
					{
						name: 'Select ALL',
						id: columns.map((x) => x.id),
						visible: columns.filter((x) => x.visible).length == columns.length,
					},
					0,
					true
				)}
				{columns.map((col, i) => this.renderListItem(col, i + 1, false))}
			</div>
		);

		return (
			<Popover placement="bottomRight" title={null} content={content} trigger="click">
				<Button>
					<FontAwesomeIcon icon={faTable} />
				</Button>
			</Popover>
		);
	}
}
