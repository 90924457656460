import models from './models';
import form from './form';
import view from './view';
import actions from './actions';
import messages from './messages';

export default {
	name: 'Data management',
	form,
	view,
	actions,
	models,
	messages,
};
