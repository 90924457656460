export enum KEYS {
	'ALL' = 'all',
	'NAME' = 'nm',
	'CONTENT' = 'ct',
	'DATE' = 'dt',
	'YEAR' = 'yr',
	'MONTH' = 'MO',
	'FILE_TYPE' = 'FILE_TYPE',
	'REFERENCE' = 'rf',
	'BY_DATE' = 'by-date',
	'BY_REF' = 'by-ref',
	'BY_OTHER' = 'by-other',
	'DCEQ' = 'DCEQ', // date creation eq
	'DCBE' = 'DCBE', // date creation before
	'DCAF' = 'DCAF', // date creation after
	'DUEQ' = 'DUEQ', // date update eq
	'DUBE' = 'DUBE', // date update before
	'DUAF' = 'DUAF', // date update after
	'YCEQ' = 'YCEQ', // year creation eq
	'YCBE' = 'YCBE', // year creation before
	'YCAF' = 'YCAF', // year creation after
	'YUEQ' = 'YUEQ', // year update eq
	'YUBE' = 'YUBE', // year update before
	'YUAF' = 'YUAF', // year update after
	'MCEQ' = 'MCEQ', // month creation eq
	'MCBE' = 'MCBE', // month creation before
	'MCAF' = 'MCAF', // month creation after
	'MUEQ' = 'MUEQ', // month update eq
	'MUBE' = 'MUBE', // month update before
	'MUAF' = 'MUAF', // month update after
}
