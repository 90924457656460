export default {
	name: 'Label',
	name_plural: 'Labels',
	labels: {
		name: 'Name',
		private: 'Private',
		create_at: 'Created at',
		update_at: 'Update at',
	},
	actions: {
		create_new: 'New Label',
		created: 'Create label',
		updated: 'Update label',
		save: 'Save',
		cancel: 'Cancel',
	},
	errors: {
		access_denied: 'Access denied',
		error_occured: 'An error occured when retrieving labels',
		not_applied: 'An error occured when applying tags to resources',
	},
	success: {
		created: 'Label created',
		updated: 'Label updated',
		applied: 'Selected records have been successfully updated.',
	},
};
