import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Modal, Space, Typography } from 'antd';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ReferenceSelect from 'ui/components/Form/ReferenceSelect';
import { pushNotification } from 'src/utils/PushNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { migrateAndDeleteUser } from 'ui/modules/Core/services/user.service';
import { IUser } from 'ui/modules/Core/types/interfaces/user.interface';
import { useUserContext } from 'src/libs/contextLib';
import _ from 'lodash';
// constants
const { Text } = Typography;

interface DeleteUserModalProps {
	referenceListConsumerProps: any;
	user: IUser | undefined;
	users: IUser[] | undefined;
	isOpen: boolean;
	setIsOpen: (v: boolean) => void;
	setLoader: (v: boolean) => void;
	refreshList: () => void;
}

const DeleteUserModal: FC<DeleteUserModalProps> = ({
	referenceListConsumerProps,
	user,
	users,
	isOpen,
	setIsOpen,
	setLoader,
	refreshList,
}) => {
	const { addToast } = useToasts();
	const { t: translate } = useTranslation();
	const { user: userConnected } = useUserContext() as any;
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<{ value: string; label: string } | null>(null);
	const userIds = user ? [user.id] : users?.map((user) => user.id) || [];
	if (users?.length === 1) {
		user = users[0];
	}

	useEffect(() => {
		if (isOpen) {
			setSelectedUser(null);
			if (userIds.includes(userConnected.user_id)) {
				pushNotification(
					addToast,
					translate('model.user.messages.restricted'),
					'info',
					translate('model.user.messages.info_delete_me')
				);
				closeModal();
			} else setIsOpenModal(true);
		}
	}, [isOpen]);

	const onConfirmDeleteUser = () => {
		let endMessage = (
			<Text>
				{translate('model.user.messages.confirm_lose_resources')}
				{translate('model.user.messages.action_can_t_undone')}
			</Text>
		);
		if (selectedUser) {
			endMessage = (
				<Text>
					{translate('model.user.messages.confirm_migrate_user')}
					<Text strong>{selectedUser.label}</Text>
					{`? ${translate('model.user.messages.action_can_t_undone')}`}
				</Text>
			);
		}
		Modal.confirm({
			title: translate('model.user.messages.attention'),
			content: (
				<Text>
					{translate('model.user.messages.confirm_delete_user')}
					{user ? (
						<Text strong>{`${user?.first_name || '_'} ${user?.last_name || '_'}`}</Text>
					) : (
						<Text strong>{translate('model.user.messages.multiple_users')}</Text>
					)}
					{endMessage}
				</Text>
			),
			onOk: () => {
				onDeleteUser();
			},
		});
	};

	const onDeleteUser = async () => {
		if (userIds.length) {
			setLoader(true);
			for (const userId of userIds) {
				try {
					await migrateAndDeleteUser(userId, selectedUser?.value || null);
					pushNotification(addToast, translate('model.user.messages.success_delete_user'), 'success');
				} catch (err) {
					pushNotification(
						addToast,
						err?.response?.data?.message || translate('model.user.messages.error_delete_user'),
						'error'
					);
				}
			}

			refreshList();
			setLoader(false);
		}
	};

	const closeModal = () => {
		setIsOpenModal(false);
		setIsOpen(false);
	};
	return (
		<Modal
			onOk={() => {
				closeModal();
				onConfirmDeleteUser();
			}}
			title={
				<Space className="d-flex align-item-center" style={{ fontSize: 14, color: '#5a5867', columnGap: 5 }}>
					<FontAwesomeIcon icon={faTrash} />
					<Text>{translate('actions.delete')}</Text>
					{user ? (
						<Text strong>{`${user?.first_name || '_'} ${user?.last_name || '_'}`}</Text>
					) : (
						<Text>{translate('model.user.messages.multiple_users')}</Text>
					)}
				</Space>
			}
			open={isOpenModal}
			onCancel={() => closeModal()}
		>
			<Space direction="vertical" className="w-100">
				<Text style={{ fontSize: 13 }}>{translate('model.user.messages.select_migrate_user')}</Text>
				<ReferenceSelect
					allowClear
					recordtype="user"
					isReferenceListLoaded={referenceListConsumerProps?.isReferenceListLoaded}
					setReferenceListValues={referenceListConsumerProps?.setReferenceListValues}
					getReferenceListValues={referenceListConsumerProps?.getReferenceListValues}
					optionlabelfunction={(data: any) => `${data.first_name} ${data.last_name}`}
					onChange={(value: string, option: string[]) => {
						if (!value || _.isNil(option) || _.isEmpty(option)) {
							setSelectedUser(null);
						} else
							setSelectedUser({
								value,
								label: option[0],
							});
					}}
				/>
			</Space>
		</Modal>
	);
};

export default DeleteUserModal;
