export default {
	name: 'Twitter',
	twitter_news: 'Twitter News',
	tooltip: 'Filter tweets and trends',
	module_description:
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
	link_description: 'Lorem ipsum dolor sit amet',
	last_tweets: 'Last Tweets',
	dashboard: 'Dashboard',
	add_account: 'Add Twitter Account to follow',
	see_more: 'See more tweets like this',
	number_posts_last_days: 'Number of posts Last 30 days',
	analytics: 'Twitter analytics',
	tooltip_analytics: 'See the last tweets',
	tags: 'Tags',
	accounts: 'Accounts',
	charts: {
		per_month: 'Number of tweets per month',
		per_author: 'Number of tweets per author',
	},
};
