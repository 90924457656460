import React from 'react';

function FloatLabel(props) {
	const { children, label, className } = props;

	return (
		<div className={`ant-row ${className}`}>
			<div className="ant-col ant-form-item-label">
				<label>{label}</label>
			</div>
			<div className="ant-col ant-form-item-control">
				<div className="ant-form-item-control-input">{children}</div>
			</div>
		</div>
	);
}

export default FloatLabel;
