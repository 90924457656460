export default {
	replace: 'Replace',
	save: 'Save',
	delete: 'Delete',
	check: 'Check',
	cancel: 'Cancel',
	to_proceed: ' to proceed',
	proceed: 'Proceed',
	correction: 'Correction',
	correction_sure: 'Are you sure you want to apply the replacement ? The data will be moved permanently.',
	correction_description: 'Are you sure you want to apply the replacement ? The data will be moved permanently.',
	create_contract: 'Create contract',
	create_resource_group: 'Create resource group',
	approval_request: 'Approval request',
	delete_contract: 'Delete contract',
	delete_resource_group: 'Delete resource group',
	contract: 'Contract',
	resource_group: 'Resource group',
	amend: 'Amend',
	disapprove: 'Disapprove',
	approve: 'Approve',
	update: 'Update',
	edit: 'Edit',
	revert_as_draft: 'Revert as draft',
	are_you_sure_to_delete_item: 'Are you sure you want to delete this item?',
	are_you_sure_to_delete_items: 'Are you sure you want to delete these items?',
	are_you_sure_to_approve_item: 'Are you sure you want to approve this item?',
	are_you_sure_to_disapprove_item: 'Are you sure you want to disapprove this item?',
	open: 'Open',
	access_to_sharing: 'Access to sharing',
	sharing: 'Sharing',
	pending: 'Pending sharing',
	dry_run: 'Dry run',
};
