// Ant design & Icons
import { faAbacus } from '@fortawesome/pro-light-svg-icons';
// utils
import { dataManagement, getApiPrefix, server } from 'src/utils/api-prefix.helper';
import _ from 'lodash';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: faAbacus,
	url: `${serverUrl}${dataManagement}/pipeline`,
	recordid: 'id', // required for duplication !
	urlParams: '',
	path: '/data-prep/pipeline',
	pageTitle: {
		list: 'Pipeline',
		action: {
			create: 'Pipeline',
			edit: 'Edit Pipeline',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Code',
			field: 'code',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Status',
			field: 'last_status',
			type: 'tag',
			typeParams: {
				mapping: {
					success: 'green',
					error: 'red',
					ready: 'purple',
				},
				null_value: 'Not yet run',
			},
			minWidth: 90,
			values: ['success', 'error', 'under_edit', 'ready', 'running'],
		},
		{
			headerName: 'Last data extraction trigger',
			field: 'last_run_at',
			type: 'datetime',
			minWidth: 200,
			description: 'Last execution date of the workflow',
		},
	],
};
