import React, { Component } from 'react';

import { keyBy, sortBy } from 'lodash';

import { getOrganizationCachedSettings } from 'utils/dataUtils';

import config from 'config';
import { getRuleset } from 'ui/modules/OrganizationSettings/services/services.references';
import ReferenceListContext from './ReferenceListContext';

export default class ReferenceListProvider extends Component {
	state = {
		referenceLists: [],
	};

	setReferenceListValues(referenceListCode, values) {
		this.setState({
			[referenceListCode]: {
				indexedValuesById: keyBy(values, 'id'),
				indexedValuesByCode: keyBy(values, 'code'),
				values: sortBy(values, 'name'),
				isLoading: false,
				isLoaded: true,
			},
		});
	}

	prepareData(referenceListCode, values) {
		const { parentField } = config.records[referenceListCode];

		const indexedById = {
			...this.state.indexedValuesById,
		};
	}

	isReferenceListLoaded(referenceListCode) {
		return this.state[referenceListCode]?.isLoaded;
	}

	getReferenceListValues(referenceListCode) {
		return this.state[referenceListCode]?.values;
	}

	async getReferenceLists(organizationCode) {
		const referenceLists = await getOrganizationCachedSettings(
			organizationCode,
			'references.reference_lists',
			async () => {
				const result = await getRuleset();

				const referenceLists = result && Array.isArray(result) ? sortBy(result, 'name') : [];
				this.setState({ referenceLists });
				return referenceLists;
			}
		);

		return referenceLists;
	}

	render() {
		return (
			<ReferenceListContext.Provider
				value={{
					setReferenceListValues: this.setReferenceListValues.bind(this),
					isReferenceListLoaded: this.isReferenceListLoaded.bind(this),
					getReferenceListValues: this.getReferenceListValues.bind(this),
					getReferenceLists: this.getReferenceLists.bind(this),
				}}
			>
				{this.props.children}
			</ReferenceListContext.Provider>
		);
	}
}
