export default {
	name: 'Category',
	name_plural: 'Categories',
	new_record_title: 'New category',
	actions: {
		create_new: 'Create a new category',
	},
	tabs: {
		first: {
			title: 'Summary',
			sections: {
				general: 'General',
				template_fields: 'Template fields',
				categories: 'categories',
			},
			inactive: 'Inactive',
			name: 'Name',
			description: 'Description',
			code: 'Code',
			geographic_coverage: 'Geographic coverage',
			date_coverage: 'Temporal coverage',
			parent: 'Parent',
			created: 'Created',
			updated: 'Updated',
			type: 'Type',
			filter_value: 'Filter value',
			filter_type: 'Filter type',
			mandatory: 'Mandatory',
		},
		second: {
			title: 'Related records',
			sections: {
				datasets: 'Datasets',
			},
			name: 'Name',
		},
	},
	messages: {
		error_get_list: 'An error has occured when getting categories',
	},
};
