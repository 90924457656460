export default {
	add: 'Add',
	cancel: 'Cancel',
	close: 'Close',
	confirm: 'Confirm',
	delete: 'Delete',
	edit: 'Edit',
	edit_schedule: 'Edit schedule',
	download: 'Download',
	upload: 'Upload',
	save: 'Save',
	create: 'Create',
	preview: 'Preview',
	view: 'View',
	save_execute: 'Save and execute',
	save_close: 'Save and close',
	save_and_end_execution: 'Save and end execution',
	save_and_execute_end: 'Save and execute until the end',
	end_execution: 'End execution',
	saving: 'Saving...',
	next_step: 'Next step',
	previous_step: 'Previous step',
	input_preview: 'Input preview',
	output_preview: 'Output preview',
	ending: 'Ending....',
	update_workflow_state: 'Update workflow(s) state',
	canRefreshAndView: 'Refresh and visualize record',
	visualize: 'Visualize record',
	cannot_edit: 'This entry is not editable',
	cannot_delete: "Can't delete shared data",
	clone: 'Clone record',
	cannot_clone: 'This entry cannot be clonned',
	is_using_workflow: ' is currently using the workflow',
	duplicate: 'Duplicate record',
	fork: 'Fork record',
	priority_rule: 'Edit priority rules',
	not_access: 'This entry is not editable',
	execute_workflow: 'Execute Workflow',
	execute_data_prep: 'Execute data prep',
	cannot_execute_data_prep: 'This data prep cannot run automatically',
	cannot_execute_workflow: 'This workflow cannot run automatically',
	execute_freight_rate: 'Execute Freight rate',
	execute_line_up_consolidation: 'Execute Lineup consolidation',
	canRefreshAndDownload: 'Refresh and download',
	canDownload: 'Download',
	stats: 'Stats',
	dry_run: 'Dry run',
	scan: 'Scan',
	scan_dataset: 'Scan dataset',
	scan_metadata: 'Scan meta usage',
	execute: 'Execute',
	back: 'Back',
};
