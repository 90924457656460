export default {
	statistics: {
		nb_files_years: 'Number of files by years',
		nb_files_products: 'Number of files by top 10 products',
		nb_files_sources: 'Number of files by top 10 sources',
	},
	insights: {
		summary: 'Summary',
		key_points: 'Key points',
		conclusion: 'Conclusion',
		insights_are_not_currently_available: 'Insights are not currently available',
	},
	folders: {
		sections: {
			filters: 'Filters',
			group_by: 'Group by',
		},
	},
	empty: {
		no_levels_to_map: 'No levels to map',
		no_filters_to_map: 'No filters to map',
	},
};
