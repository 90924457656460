import React from 'react';
import { faTable } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'antd';
import { basePath, data, getApiPrefix, server } from 'src/utils/api-prefix.helper';
// services
import { getDatasetService } from 'ui/modules/Core/services/dataset.service';
// helpers
import _ from 'lodash';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceData: false,
	iconName: faTable,
	oldUrl: `${baseApiUrl}dataset`,
	url: `${serverUrl}${data}/datasets`,
	urlParams:
		'relation=[user,data_steward,source,location,product,product_category,category,workflow,source_owner,source_channel,labels]',
	urlOneDatasetParams:
		'relation=[user,data_steward,source,location,location_group,product,product_category,category,workflow,source_owner,source_channel,dataset_field,dataset_version,labels]',
	path: '/datasets',
	pageTitle: {
		list: 'Datasets',
		action: {
			create: 'New dataset',
			edit: 'Edit dataset',
		},
	},
	actions: [
		<Button
			onClick={function () {
				this.props.history.push('/search');
			}}
			key="data_catalog"
		>
			Data catalog
		</Button>,
		<Button
			onClick={function () {
				this.props.history.push('/data-prep/pipeline');
			}}
			key="query"
		>
			Data Prep
		</Button>,
	],
	columns: [
		{
			headerName: 'Shared',
			field: 'shared',
			type: 'boolean',
			minWidth: 100,
			width: 100,
		},
		{
			headerName: 'Location',
			field: 'location',
			minWidth: 100,
			width: 100,
			type: 'reference',
		},
		{
			headerName: 'Product',
			field: 'product',
			minWidth: 100,
			width: 100,
			type: 'reference',
		},
		{
			headerName: 'Category',
			field: 'category',
			minWidth: 160,
			width: 160,
			type: 'reference',
		},
		{
			headerName: 'Source',
			field: 'source',
			type: 'reference',
			minWidth: 180,
			width: 180,
		},
		{
			headerName: 'Name',
			field: 'name',
			minWidth: 220,
			width: 400,
		},
		{
			headerName: 'Frequency',
			field: 'frequency',
			minWidth: 135,
		},
		{
			headerName: 'Labels',
			field: 'labels',
			type: 'labels',
			minWidth: 200,
		},
		{
			headerName: 'Code',
			field: 'code',
			minWidth: 200,
		},
		{
			headerName: 'Data steward',
			minWidth: 150,
			field: 'data_steward',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Data owner',
			minWidth: 150,
			field: 'source_owner',
			type: 'reference',
			referenceType: 'source',
		},
		{
			headerName: 'Data channel',
			minWidth: 150,
			field: 'source_channel',
			type: 'reference',
			referenceType: 'source',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Shareable',
			field: 'can_share_data',
			type: 'boolean',
		},
		{
			headerName: 'Creation date',
			field: 'created_at',
			description: 'Date when dataset was created',
			type: 'date',
			minWidth: 200,
		},
		{
			headerName: 'Last execution',
			field: 'workflow.last_run_at',
			description: 'Last execution date of the workflow',
			minWidth: 200,
			width: 200,
			type: 'date',
		},
		{
			headerName: 'Last data update',
			field: 'last_data_change_date',
			type: 'date',
			description: 'Last date at which the number of rows in the dataset has changed',
			minWidth: 200,
		},
		{
			headerName: 'Last Loading Date',
			field: 'last_loaded_data_date',
			type: 'date',
			description: 'Last date of data loading in the workflow',
			minWidth: 220,
		},
		{
			headerName: 'Last extracted data',
			field: 'last_extracted_data_date',
			type: 'date',
			description: 'Last date of data extraction in the workflow',
			minWidth: 220,
		},
	],
	sortModel: [
		{
			colId: 'category',
			sort: 'asc',
		},
		{
			colId: 'name',
			sort: 'asc',
		},
	],
	canView: true,
	canEdit: true,
	customCreate: true,
	canDelete: true,
	canbeCloned: true,
	canFilter: ['location', 'product'],
	hasShared: true,
	loadLastUpdate: true,
	canChangeTags: true,
	getService: getDatasetService,
	navigateByCode: true,
	forecast_type: ['FORECAST', 'PROD-FORECAST'],
};
