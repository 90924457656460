export default {
	success: {
		contract_created: 'Contract created successfully',
		contract_updated: 'Contract updated successfully',
		contract_deleted: 'Contract deleted successfully',
		contract_approved: 'Contract approved successfully',
		contract_rejected: 'Contract rejected successfully',
		resource_group_created: 'Resource group created successfully',
		resource_group_updated: 'Resource group updated successfully',
		resource_group_deleted: 'Resource group deleted successfully',
		resource_group_approved: 'Resource group approved successfully',
		resource_group_rejected: 'Resource group rejected successfully',
		delete: 'Deleted successfully',
	},
	info: {
		cant_delete_contract: "You can't delete active contract",
	},
	error: {
		contract_already_exists: 'Contract already exists has draft or approval for this contractee',
		not_authorized: 'You are not authorized to perform this action',
		not_authorized_or_not_exists: 'You are not authorised to perform this action or this resource does not exists',
		not_authorized_or_ongoing_contract:
			'You are not authorised to perform this action or this resource has ongoing contract',
		error_getting_contracts: "Couldn't get contracts",
		getting_resource_group: "Couldn't get list resource group",
	},
};
