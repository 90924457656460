import { IPermissions } from 'ui/modules/Core/types/interfaces/user.interface';

// get if the user can access to module
export const canAccess = (myPermissions: IPermissions, permissionRecord: string) => {
	return myPermissions?.[permissionRecord]?.can_read;
};

// get if the user can create new record
export const canCreate = (myPermissions: IPermissions, permissionRecord: string) => {
	return myPermissions?.[permissionRecord]?.can_create;
};

// get if the user can update new record
export const canUpdate = (myPermissions: IPermissions, permissionRecord: string) => {
	return myPermissions?.[permissionRecord]?.can_update;
};
