import React, { Component } from 'react';
import { Tag, Popover } from 'antd';

export default class listRenderer extends Component {
	render() {
		let value = this.props.value || this.props.null_value;
		if (!value) return null;
		if (!this.props.node) return value;

		value = value.split(this.props.colDef?.tag_separator || ',');

		const { mapping } = this.props;
		// const color = mapping ? mapping[value] : this.props.color; // default color is no-color = grey

		return value.map((tag, index) => (
			<span key={index}>
				<Tag color={mapping ? mapping[tag] : this.props.color}>{tag}</Tag>
			</span>
		));
	}
}
