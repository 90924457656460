import DataService from 'src/utils/DataService';
import ConfigReport from 'ui/modules/Reporting/config/report.config';

/**
 * open notebook
 * @param id
 * @returns
 */
export const openNotebook = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/open`,
		urlParams: '',
	}).post({});
};

/**
 * publish notebook
 * @param id
 * @returns
 */
export const publishNotebook = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/publish`,
		urlParams: '',
	}).update({});
};

/**
 * save notebook
 * @param id
 * @param payload
 * @returns
 */
export const saveNotebook = async (id: string, payload: any) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/save`,
		urlParams: '',
	}).put(payload);
};

/**
 * close notebook
 * @param id
 * @returns
 */
export const closeNotebook = async (id: string) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/force-close`,
		urlParams: '',
	}).post({});
};

/**
 * save and close notebook
 * @param id
 * @param payload
 * @returns
 */
export const saveAndCloseNotebook = async (id: string, payload: any) => {
	return await new DataService({
		url: `${ConfigReport.url}/${id}/save-and-close`,
		urlParams: '',
	}).put(payload);
};
