import React from 'react';
import { Result, Button } from 'antd';
import OneColumnPage from 'ui/layout/OneColumnPage/OneColumnPage';

class GlobalError extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
		};
	}

	handleClick() {
		this.props.history.goBack();
	}

	render() {
		return (
			<OneColumnPage id="globalError" breadcrumb={false}>
				<div className="h-100 d-flex align-items-center justify-content-center">
					<Result
						status="500"
						title="500"
						subTitle={this.props.translate('notification.messages.global_error')}
						extra={
							this.props.history ? (
								<Button type="primary" onClick={() => this.handleClick()}>
									{this.props.translate('notification.actions.back_to_previous_page')}
								</Button>
							) : null
						}
					/>
				</div>
			</OneColumnPage>
		);
	}
}

export default GlobalError;
