export default {
	name: 'Search',
	errors: {
		error_download: 'Error downloading results',
		error_download_content: 'Error downloading content',
		error_get_data: 'Error getting dataset signed urls',
	},
	messages: {
		empty_dataset: 'The dataset is empty',
		no_filter: 'References Filter is empty, select at least one reference.',
	},
	labels: {
		search_placeholder: 'Search datasets by term...',
	},
};
