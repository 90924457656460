export default {
	name: 'Production',
	name_plural: 'Production',
	title: 'Production',
	new_record_title: 'New production',
	actions: {
		back: 'Back to production priority rules',
		back_form: 'Back to production parameters',
		priority_rules: 'Priority rules',
		configuration: 'Configuration',
		save: 'Save changes in current version',
		publish: 'Publish version and start a new one',
		reset_changes: 'Reset changes',
		refresh_view: 'Update view',
		download: 'Download',
		refresh_data: 'Refresh data',
		view: 'View',
		rows_validation: 'Validate selected rows',
		rows_validation_and_refresh: 'Validate selected rows and refresh data',
	},
	cropyear_change:
		'Period start month will be used to time stamp your forecast values (e.g. under Sep/Aug, all forecast will be set as of the 1st Sep of the given crop year). Any modification after you started to enter forecasts might disrupt the results. You may need to download and modify the cut-off date of your forecast and re-upload under a NEW forecast set it in case you need to change the crop year later on.',
	settings: {
		title: 'Production settings',
	},
	modals: {
		add_dataset: {
			title: 'Add dataset',
		},
	},
	messages: {
		about_production: 'About this production',
		last_refresh_done: 'Last data refresh has been done {{ since }} on {{ date }}',
		no_editable_forecast: "No forecast found or the selected forecast can't be modified",
		editing_forecast_x: 'You are currently editing forecast {{ name }}',
		data_refreshed_successfully: 'Data successfully refreshed',
		refresh_started: 'Production data refresh started',
		refresh_started_explanation: 'Please wait for the refresh to complete before accessing the production view',
		bulk_update_successful: 'Forecast successfully updated',
		update_period_dataset: 'New validation has been set successfully',
		please_refresh: 'Please refresh your data since new validation dates has been defined',
		rows_validation:
			'Some updates exist for the following datasets since your last validation. You can review the list below and change the date that marks the end of finalized data.',
	},
};
