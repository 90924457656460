import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'locales/en';
import enModules from 'locales/modules/en';

// the translations
export const resources = {
	en: {
		translation: { ...enModules, ...en }, // Exp to use new module translations: pipeline.models.actions.create_new
	},
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en',
		fallbackLng: 'en',

		keySeparator: '.',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			useSuspense: false, //   <---- this will do the magic
		},
		/*
    backend: {
        loadPath: 'locales/{{ns}}.{{lng}}.json'
    }
    */
	});

export default i18n;
