export default {
	name: 'Pipeline',
	name_plural: 'Pipeline',
	new_record_title: 'New Pipeline',
	base: {
		name: 'Pipeline',
		name_plural: 'Pipelines',
	},
	actions: {
		create_new: 'Create a new Pipeline',
	},
	no_x: 'No Pipeline',
};
