import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/pro-light-svg-icons';

const dateNow = new Date(new Date().toLocaleString('en', { timeZone: 'Europe/Dublin' }));

export default class showLockRenderer extends Component {
	render() {
		const { user } = this.props.colDef;
		const dateUpdated_at = new Date(
			new Date(this.props.data.updatedAt).toLocaleString('en', { timeZone: 'Europe/Dublin' })
		);

		const diffTime = Math.abs(dateNow - dateUpdated_at);
		return (
			<FontAwesomeIcon
				color={
					!this.props.data.is_used_by?.includes(user?.user_id) && diffTime / 60000 < 6 ? 'black' : '#cccccc'
				}
				icon={
					!this.props.data.is_used_by?.includes(user?.user_id) && diffTime / 60000 < 6 ? faLock : faLockOpen
				}
			/>
		);
	}
}
