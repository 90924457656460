import React from 'react';
// ant design & Icons
import { faPassport } from '@fortawesome/pro-light-svg-icons';
// utils
import { server, basePath } from 'src/utils/api-prefix.helper';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';
// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceRecord: 'custom-code',
	referenceData: true,
	navigateByCode: true,
	groupOf: 'custom-code',
	iconName: faPassport,
	url: `${baseApiUrl}custom-code`,
	urlParams: 'relation=[user,direct_parent]',
	path: '/custom-codes',
	elasticSearchKey: 'CustomCode',
	relatedReferences: [],
	keysMatching: [],
	pageTitle: {
		list: 'Custom codes',
		action: {
			create: 'New custom code',
			edit: 'Edit custom code',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			cellRendererFramework: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/custom-codes/${data.direct_parent.code}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'HS2',
			field: 'hs2',
		},
		{
			headerName: 'HS4',
			field: 'hs4',
		},
		{
			headerName: 'HS6',
			field: 'hs6',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: true,
	parentField: 'parent_custom_code_id',
};
