import React from 'react';
//Ant design & Icons
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
//utils
import { server, basePath } from 'src/utils/api-prefix.helper';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';
// constants
const baseApiUrl = `${server}${basePath}/`;

export default {
	referenceRecord: 'location',
	referenceData: true,
	navigateByCode: true,
	iconName: faMapMarkerAlt,
	url: `${baseApiUrl}location`,
	path: '/locations',
	elasticSearchKey: 'Location',
	relatedReferences: [],
	keysMatching: [],
	pageTitle: {
		list: 'Locations',
		action: {
			create: 'New location',
			edit: 'Edit location',
		},
	},
	columns: [
		{
			headerName: 'Inactive',
			field: 'is_archived',
			type: 'boolean',
			initialHide: true,
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Parent',
			field: 'direct_parent',
			cellRendererFramework: ({ data }: any) => {
				return data.direct_parent ? (
					<ReferenceField
						disabled={data.direct_parent.deleted_at}
						value={data.direct_parent.name}
						url={`/${data.direct_parent.is_group ? 'locations-group' : 'locations'}/${
							data.direct_parent.code
						}`}
					/>
				) : (
					'_'
				);
			},
		},
		{
			headerName: 'Type',
			field: 'level_name',
		},
		{
			headerName: 'Created by',
			minWidth: 150,
			field: 'created_by_user',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated',
			field: 'updated_at',
			type: 'datetime',
		},
	],
	parentField: 'parent_id',
};
