import { createContext } from 'react';

export default createContext({
	getDataValues: (key) => {},
	// setReferenceListValues: (referenceListCode, values) => {},
	isDataLoaded: (key) => {},
	isDataLoading: (key) => {},
	getDataElement: (data_key, key) => {},
	getData: () => {},
});
