export default {
	name: 'Location group',
	name_plural: 'Location Groups',
	new_record_title: 'New Location group',
	description: '',
	actions: {
		create_new: 'Create a new location group',
		edit: 'Save',
	},
};
