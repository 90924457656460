export default {
	types: {
		success: 'Success',
		error: 'Error',
		warning: 'Warning',
		info: 'Info',
		attention: 'Attention',
	},
	messages: {
		expired_token: 'Your session has expired. Please log in again',
		access_denied: 'You are not authorized to access this page.',
		error_occured: 'An error occured',
		record_does_not_exist: 'record does not exist',
		global_error: 'An error has occured. Please, try again later.',
	},
	actions: {
		back_to_home: 'Back to home',
		back_to: 'Back to',
		list: 'list',
		back_to_previous_page: 'Back to the previous page',
	},
};
