import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Breadcrumb as AntdBreadcrumb, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import './Breadcrumb.sass';
const { Text } = Typography;

export class Breadcrumb extends Component {
	render() {
		return (
			<AntdBreadcrumb className={`Breadcrumb mt-1`}>
				<AntdBreadcrumb.Item>
					<Link to="/">
						<FontAwesomeIcon icon={faHome} className="mr-1" />
						<Text>Home</Text>
					</Link>
				</AntdBreadcrumb.Item>
				{this.props.parentPage && this.props.parentPage.url ? (
					<AntdBreadcrumb.Item
						onClick={() => {
							this.props.history.push(this.props.parentPage.url);
						}}
					>
						{this.props.t(this.props.parentPage.name)}
					</AntdBreadcrumb.Item>
				) : null}
				{this.props.parentPage && this.props.parentPage.name && !this.props.parentPage.url ? (
					<AntdBreadcrumb.Item>{this.props.t(this.props.parentPage.name)}</AntdBreadcrumb.Item>
				) : null}
				{this.props.parentPage && !this.props.parentPage.name ? (
					<AntdBreadcrumb.Item>{this.props.t(this.props.parentPage)}</AntdBreadcrumb.Item>
				) : null}
				{this.props.parents &&
					this.props.parents.map((p, index) => {
						if (!p) return null;

						return (
							<AntdBreadcrumb.Item
								key={index}
								onClick={() => {
									this.props.history.push({
										pathname: p.url,
										state: this.props.history?.location?.state,
									});
								}}
							>
								{p.name}
							</AntdBreadcrumb.Item>
						);
					})}
				<AntdBreadcrumb.Item className={this.props.withLast ? 'last' : ''}>
					{this.props.title}
				</AntdBreadcrumb.Item>
			</AntdBreadcrumb>
		);
	}
}

export default withTranslation()(Breadcrumb);
