export default {
	title: 'Compliance',
	actions: {
		save_compliance: 'Save Compliance',
		dry_run_impact: 'Dry Run impacts',
		discard_changes: 'Discard changes',
		refresh_impact_views: 'Refresh impact views',
		retrieve_compliance: 'Retrieve compliance info',
	},
	labels: {
		compliance_options: 'Compliance Options',
		compliance_impact: 'Compliance Impacts',
		source_copyright: 'Source Copyright',
		resource_copyright: 'Resource Copyright',
		blocked_by: 'is blocked by: ',
		resource_groups: 'Resource groups',
		derivatives: 'Derivatives',
		visuals: 'Visuals',
		contracts: 'Contracts',
		source: 'Source',
		generic_source: 'Generic Source',
		data_owner: 'Data Owner',
		data_channel: 'Data Channel',
		copyright_source: 'Copyright from the source',
		generic_copyright: 'Generic Copyright to use for the source',
		can_share_data: 'can be shared',
		can_be_shared_in_dashboard: 'can be shared in dashboard',
		can_be_shared_in_derivative: 'can be shared in derivative',
		must_quote: 'Source have to be quoted',
		internal_must_quote_no: 'No',
		internal_must_quote_exact: 'Exact',
		internal_must_quote_generic: 'Generic',
		can_be_shared_in_report: 'can be shared in a report',
		can_be_downloaded: 'can be downloaded',
	},
	messages: {
		enable_options: 'To enable all options activate enabled sharing',
		disable_options:
			'Review the implications of modifying compliance settings on the resource groups and its contracts',
		shareability_settings_impact: 'Your compliance settings has no impact yet',
		configure_settings_impact: 'Press "Dry Run" and see if there’s any impact',
		shareability_enabled: 'is shareable and not blocked by any other resource',
		shareability_enabled_guide: 'To share it, add it in a resource group and use it in a contract',
		source_info: 'Choose source copyright information',
		resource_groups_desc: 'will no longer exist in the following resource groups',
		derivatives_desc: 'List of derivatives that will be removed from their respective resource groups.',
		visuals_desc:
			'List of dashboards/reports/screenshots/etc, that will be removed from their respective resource groups.',
		confirm_save_compliance:
			'There may be some impacts you need to check before saving compliance settings. Do you want to proceed?',
	},
	tooltips: {
		can_share_data: 'If yes, dataset itself can be added to a resource group',
		can_be_shared_in_dashboard: 'If no, any dashboard using this dataset cannot be added to a resource group',
		can_be_shared_in_derivative: 'If no, any derivative using this dataset cannot be shared',
		must_quote:
			'If exact client will see copyright from the source, if generic client will see generic copyright and its source.',
		can_be_shared_in_report: 'If no, a report using this data cannot be shared',
		can_be_downloaded:
			'If yes, dataset can be downloaded if shared, dashboard can be downloaded if shared, derivative can be download if shared',
		data_owner: 'Who legally owns the dataset',
		source: 'Who produces the dataset',
		generic_source: 'Alternative source to use if the original source is not relevant',
		data_channel: 'Who provides the dataset',
	},
	success: {
		success_ave_compliance: 'Compliance settings saved successfully',
	},
	errors: {
		error_retrieve_compliance: 'An error occurred when retrieving compliance details',
		error_occurred_run_impacts: 'An error occurred when executing impacts',
		error_occurred_save_compliance: 'An error occurred while saving compliance options',
	},
	validations: {
		valid_copyright_from_the_source: 'Copyright from the source is required',
		valid_generic_source: 'Generic source is required',
		valid_generic_copyright: 'Generic copyright to use for the source is required',
	},
};
