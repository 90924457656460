import { faShip } from '@fortawesome/pro-light-svg-icons';
import { basePath, server } from 'src/utils/api-prefix.helper';
const baseApiUrl = `${server}${basePath}/`;
export default {
	referenceData: false,
	iconName: faShip,
	url: `${baseApiUrl}production`,
	parentPage: {
		name: 'Fundamentals',
	},
	recordid: 'production_id',
	urlParams: '',
	path: '/fundamentals/production',
	pageTitle: {
		list: 'Production',
		action: {
			create: 'New production',
			edit: 'Edit production',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Shared',
			field: 'shared',
			type: 'boolean',
			minWidth: 100,
			width: 100,
		},
		{
			headerName: 'Created',
			field: 'createdAt',
			type: 'datetime',
		},
		{
			headerName: 'Last finalized at',
			field: 'lastSnapShotCreatedAt',
			type: 'datetime',
		},
		{
			headerName: 'Tags',
			field: 'tag',
			type: 'label',
		},
	],
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: false,
	canbeCloned: false,
	canbeDuplicate: true,
	askCode: true,
	canbeSettings: true,
	canRefreshAndView: true,
	canAccessForecasts: true,
	canChangeTags: true,
};
