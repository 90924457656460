import { createContext } from 'react';

export default createContext({
	events: [],
	notifications: [],
	notificationReady: null,
	subscribeToObject: (objectType, objectId) => {},
	unsubscribeFromObject: (objectType, objectId) => {},
	subscribeToType: (objectType, objectId) => {},
	unsubscribeFromType: (objectType, objectId) => {},
	pushNotification: () => {},
	popNotification: () => {},
	replaceNotification: () => {},
});
