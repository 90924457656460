import React, { Component } from 'react';

import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export default class datetimeCellRenderer extends Component {
	render() {
		let timezone = moment.tz.guess();
		let { value } = this.props;
		if (this.props.value?.value) {
			value = this.props.value.value;
			timezone = this.props.value.timezone || timezone;
		}

		return (
			<div className="DateCellRenderer">
				<div>{value ? moment(value).tz(timezone).format('LLL') : '-'}</div>
				{value && <small>{moment(value).fromNow()}</small>}
			</div>
		);
	}
}
