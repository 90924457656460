export default {
	tabs: {
		general: 'General',
		settings: 'Settings',
		sources: 'Sources',
		rules: 'Rules',
		scheduling: 'Scheduling',
		notifications: 'Notifications',
	},
	sections: {
		base_settings: 'Base settings',
		notifications: 'Notifications',
	},
	fields: {
		port_detection_radius: 'Port detection radius',
		accepted_date_error_before: 'Accepted date error before',
		accepted_date_error_after: 'Accepted date error after',
		location: 'Location',
		products: 'Products',
		freshness_threshold: 'Freshness threshold',
		prod_type: 'Prod type',
		prime: 'Prime',
	},
	infos: {
		port_detection_radius: 'Port detection radius',
		accepted_date_error_before: 'Accepted date error before',
		accepted_date_error_after: 'Accepted date error after',
		location: 'Location',
		products: 'products',
		freshness_threshold: 'Freshness threshold',
		prod_type: 'Prod type',
		prime: 'Prime',
	},
};
