export default {
	name: 'Account',
	name_plural: 'Accounts',
	list: 'Twitter Account',
	new_record_title: 'New account',
	accounts_to_follow: 'Accounts to follow',
	actions: {
		create_new: 'Follow a new Account',
	},
	notification: {
		title: 'Saved Successfully',
		children: "the crawling of the tweets won't be done immediatly, it will take at least one hour.",
	},
	formitem: {
		tooltip: 'Account ID',
	},
	tabs: {
		first: {
			title: 'Summary',
			sections: {
				general: 'General',
				template_fields: 'Template fields',
				accounts: 'accounts',
			},
			name: 'Handle',
			created: 'Created',
			updated: 'Updated',
			type: 'Type',
			filter_value: 'Filter value',
			filter_type: 'Filter type',
			mandatory: 'Mandatory',
		},
		second: {
			title: 'Related records',
			sections: {
				datasets: 'Datasets',
			},
			name: 'Name',
		},
	},
};
