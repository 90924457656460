import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Table, Empty, Button, Timeline, Space, Row, Col } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import DataService from 'utils/DataService';

const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={faBars} style={{ cursor: 'pointer', color: '#999' }} />);
const SortableItem = sortableElement((props) => <tr {...props} />);

const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export class BulkExecuteLineupConsolidation extends Component {
	constructor(props) {
		super(props);

		this.initialValues = this.props.value;
		this.formFieldName = this.props.name;

		this.state = {
			dataSource: [],
			loading: false,
		};

		this.columns = [
			{
				title: '',
				dataIndex: 'sort',
				width: 30,
				className: 'drag-visible',
				render: () => <DragHandle />,
			},
			{
				title: 'Code',
				dataIndex: 'code',
				key: 'code',
				className: ['drag-visible', 'visible-name'],
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				className: ['drag-visible', 'visible-name'],
			},
			{
				title: 'Product',
				dataIndex: 'product',
				key: 'product',
				render: (_text, record) => record?.product?.name || '',
				className: ['drag-visible', 'visible-name'],
			},
		];
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		// console.log("onSortEnd", { oldIndex, newIndex });

		if (oldIndex !== newIndex) {
			this.setState({ dataSource: arrayMove(this.state.dataSource, oldIndex, newIndex) });
		}
	};

	DraggableBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = this.state.dataSource?.findIndex((x) => x.key === restProps['data-row-key']);
		return <SortableItem index={index} {...restProps} />;
	};

	DraggableContainer = (props) => (
		<SortableContainer
			useDragHandle
			helperClass="row-dragging"
			onSortEnd={this.onSortEnd}
			lockAxis="y"
			{...props}
		/>
	);

	execute() {
		this.setState({ loading: true });

		Promise.all(
			this.state.dataSource.map(
				(item) =>
					new Promise((resolve) => {
						new DataService({
							url: `https://api.dnext.io/lineup/schedule-immediately/${item?.id}`,
							token: true,
							urlParams: '',
						})
							.create()
							.then((response) => {
								resolve({ success: true, response });
							})
							.catch((error) => {
								resolve({ success: false, error });
							});
					})
			)
		)
			.then((values) => {
				// console.log(values);
				this.setState({ loading: false });
				if (this.props.onEndExecution) this.props.onEndExecution();
			})
			.catch((err) => {
				console.log('err calculate', err);
				this.setState({ loading: false });
			});
	}

	render() {
		const { dataSource, loading } = this.state;
		const { records, onCancel } = this.props;

		if (!dataSource.length) {
			this.setState({
				dataSource: records?.map((item, index) => ({
					...item,
					key: index,
					index,
				})),
			});
			return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
		}
		return (
			<div>
				<Row>
					<Col flex="30px">
						<Timeline>
							{[...Array(dataSource.length)].map((_item, index) => (
								<Timeline.Item
									key={`timeline-step-${index}`}
									dot={
										<span
											style={{
												display: 'block',
												width: '25px',
												height: '25px',
												border: '1px solid #1890ff',
												borderRadius: '50%',
												padding: '5px 0',
											}}
										>
											{index + 1}
										</span>
									}
									style={{
										height: index === dataSource.length - 1 ? 0 : index ? '55px' : '50px',
										marginTop: index ? 0 : '80px',
									}}
								/>
							))}
						</Timeline>
					</Col>
					<Col flex="auto">
						<Table
							className="form_item_table"
							columns={this.columns}
							dataSource={dataSource}
							showHeader
							pagination={false}
							components={{
								body: {
									wrapper: this.DraggableContainer,
									row: this.DraggableBodyRow,
								},
							}}
						/>
					</Col>
				</Row>
				{/* <Space align="start" style={{ width: "100%" }}>
						
					</Space> */}

				<div className="mt-4" style={{ textAlign: 'right' }}>
					<Button onClick={onCancel} className="mr-3">
						Cancel
					</Button>
					<Button type="primary" loading={loading} onClick={() => this.execute()}>
						Execute
					</Button>
				</div>
			</div>
		);
	}
}
BulkExecuteLineupConsolidation.propTypes = {
	records: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withTranslation()(BulkExecuteLineupConsolidation);
