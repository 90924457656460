export default {
	name: 'Voyage',
	name_plural: 'Voyages',
	list: 'Voyages', // on a ajouté ça pour ne change pas le name plural en général
	new_record_title: 'New freight voyage',
	actions: {
		create_new: 'Create a new freight voyage',
		edit: 'Edit freight voyage',
		save: 'Save',
	},
	messages: {},
	tabs: {
		first: {
			title: 'Summary',
			sections: {
				general: 'General',
				template_fields: 'Template fields',
				tags: 'tags',
			},
			code: 'Code',
			vessel_type: 'Vessel type',
			steps: 'Steps',
			created: 'Created',
			updated: 'Updated',
			type: 'Type',
			filter_value: 'Filter value',
			filter_type: 'Filter type',
			mandatory: 'Mandatory',
		},
		second: {
			title: 'Related records',
			sections: {
				datasets: 'Datasets',
			},
			name: 'Name',
		},
	},
};
