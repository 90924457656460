export default {
	status: {
		active: 'Active',
		inactive: 'Inactive',
	},
	message: {
		no_active: 'No active resources',
		no_inactive: 'No inactive resources',
	},
};
