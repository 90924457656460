export default {
	name: 'Source',
	name_plural: 'Sources',
	new_record_title: 'New source',
	actions: {
		create_new: 'Create a new source',
	},
	fields: {
		parent: 'Parent',
	},
	form: {
		max_day_alert: 'Maximum days without updates before an alert by email is sent',
		alert_recipient: "Alert recipients when outdated (separated by ';'",
		alert_message_outdated: 'Custom alert message for outdated dataset',
		alert_recipients_mapping: "Alert recipients when mapping issues occur (separated by ';')",
		alert_message_mapping: 'Custom alert message for mapping issues',
		help: 'You can include the following codes in your message: DATASET_NAME, DATASET_URL, NUMBER_OF_DAYS',
		public: 'Public',
		associations: 'Associations',
		private: 'Private',
		select_source_type: 'Select a source type',
		select_subscription_type: 'Select a subscription type',
		fee: 'Fee',
		no_fee: 'No fee',
	},
	view: {
		alert_recipient: 'Recipients of the outdating alerts',
		alert_message_outdated: 'Custom outdated alert message',
		alert_recipients_mapping: 'Recipients of the mapping alerts',
		alert_message_mapping: 'Custom mapping alert message',
	},
	alerting: 'Alerting',
	no_alert: 'No alert',
	no_recipient: 'No recipient',
	address: 'Address',
	city: 'City',
	state: 'State',
	postal_code: 'Postal code',
	business_activities: 'Business activities',
	contact_person: 'Contact person',
	email: 'Email',
	country_code: 'Country code',
	phone: 'Phone',
	website: 'Website',
	source_type: 'Source type',
	subscription_type: 'Subscription type',
	copyright: 'Copyright',
};
