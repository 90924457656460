import React, { Component } from 'react';

import { Button, Tooltip } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class ActionButton extends Component {
	render() {
		const { disabled, icon, action, callback, specialicon, prefix, t } = this.props;
		return (
			<Tooltip title={`${prefix || ''} ${t(`actions.${action}`)}`} placement="left" mouseLeaveDelay="0">
				<Button type="secondary" disabled={disabled} size="small" className="list_action" onClick={callback}>
					{specialicon ? icon : <FontAwesomeIcon icon={icon} />}
				</Button>
			</Tooltip>
		);
	}
}
