export default {
	name: 'Access right & sharing',
	name_plural: 'Access rights & sharing',

	validation: {
		process:
			'A request for validation concerning changes to the groups is underway. Please validate before going any further.',
	},
	created_at: 'Created at',
	created_by_user_id: 'Created by',
	share_with: 'Share with',
	availability: 'Availability',
	new_record_title: 'New group',
	all_the_time: 'All the time',
	group_membership: 'Member organizations',
	groups: 'Groups',
	previous_values: 'Previous values',
	new_values: 'New values',
	dataset: {
		rules: 'Rules',
		columns: 'Dataset fields',
		title: 'Datasets',
	},
	forecast: {
		title: 'Forecasts',
	},
	dashboard: {
		title: 'Dashboards',
	},
	tradeflow: {
		title: 'Tradeflows',
		partners: 'Partners',
		reporters: 'Reporters',
	},
	labels: {
		from: 'From',
		to: 'To',
	},

	actions: {
		create_new: 'Create a new group',

		eq: 'Equal',
		neq: 'Does not equal',
		null: 'Is empty',
		notnull: 'Is not empty',

		gt: 'Greater than',
		gte: 'Greater or equal than',
		lt: 'Lower than',
		lte: 'Lower or equal than',
		btw: 'Is between range',
		outiside: 'Is outside range',
		below95p: 'Below 95th percentile',
		above95p: 'Above 95th percentile',
		below99p: 'Below 99th percentile',
		above99p: 'Above 999th percentile',
	},
};
