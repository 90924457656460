export default {
	name: 'User',
	name_plural: 'Users',
	actions: {
		create_new: 'Create a new user',
		created: 'Create user',
		updated: 'Update user',
		delete: 'Delete user',
		change_password: 'Change my password',
		save: 'Save',
		cancel: 'Cancel',
	},
	labels: {
		about: 'About',
		user_preferences: 'User preferences',
		connectors: 'Connectors',
		general: 'General',
		rights: 'Rights',
		roles: 'Roles',
		permissions: 'Permissions',
	},
	messages: {
		attention: 'Attention',
		restricted: 'Action is restricted',
		error_get_roles: 'An error has occured when getting roles',
		error_get_permissions: 'An error has occured when getting permissions',
		error_get_user: 'An error has occured when getting user detail',
		error_create_user: 'An error has occured when creating the user',
		error_update_user: 'An error has occured when updating the user',
		error_delete_user: 'An error has occured when deleting the user',
		success_create_user: 'has been created successfully',
		success_update_user: 'has been updated successfully',
		success_delete_user: 'has been deleted successfully',
		warning_submit_user: "You're adding a user without assigning a role. Proceed without a role?",
		confirm_delete_user: 'Are you sure you want to delete ',
		confirm_migrate_user: ' and assign all associated resources to ',
		confirm_lose_resources:
			"? By doing so, you'll permanently lose access to all resources linked to this profile.",
		action_can_t_undone: ' This action cannot be reversed.',
		select_migrate_user: 'Please select a user to assign all the resources of the user(s) that will be deleted.',
		info_delete_me: 'Self-deletion is not permitted.',
		multiple_users: 'users',
	},
	new_record_title: 'New user',
	errors: {
		access_denied: 'Access denied',
	},
	success: {
		created: 'User created',
		updated: 'User updated',
	},
};
