/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './clear-toasts.sass';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

function ClearToasts({ history }) {
	const { toastStack, removeAllToasts } = useToasts();

	useEffect(() => {
		if (history?.listen) history.listen((location, action) => removeAllToasts());
	}, []);

	return toastStack?.length > 1 ? (
		<div className="clear-toast-btn" onClick={() => removeAllToasts()}>
			<div className="bloc-notif">
				<div />
				<FontAwesomeIcon icon={faTimes} />
			</div>
			<span>Clear</span>
		</div>
	) : null;
}

export default ClearToasts;
