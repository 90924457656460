import { faBusinessTime } from '@fortawesome/pro-light-svg-icons';
import { dataManagement, getApiPrefix, server } from 'src/utils/api-prefix.helper';

// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: faBusinessTime,
	url: `${serverUrl}${dataManagement}/entities/business_category`,
	urlParams: '',
	path: '/business-categories',
	pageTitle: {
		list: 'Business Categories',
		action: {
			create: 'New Business category',
			edit: 'Edit Business category',
		},
	},
	columns: [
		{
			headerName: 'Code',
			field: 'code',
		},
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Created by',
			field: 'created_by',
			type: 'reference',
			referenceType: 'user',
		},
		{
			headerName: 'Created at',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated at',
			field: 'updated_at',
			type: 'datetime',
		},
	],
};
