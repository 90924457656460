import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faEllipsisHAlt } from '@fortawesome/pro-light-svg-icons';
import { Tooltip, type MenuProps } from 'antd';
import { INavItemProps } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
import { Link } from 'react-router-dom';
import { IPermissions } from 'ui/modules/Core/types/interfaces/user.interface';
import _ from 'lodash';

type MenuItem = Required<MenuProps>['items'][number];

/**
 * add title to submenu list
 * @param label
 * @param key
 * @param children
 * @param type
 * @returns
 */
function addTitle(label: string, key: string, children?: MenuItem[], type?: 'group'): MenuItem {
	return {
		label,
		key,
		children,
		type,
	} as MenuItem;
}

/**
 * get menuItem
 * @param item
 * @param history
 * @param translate
 * @param user
 * @param isAuthenticated
 * @param bookmarks
 * @returns
 */
export function getItem(
	item: INavItemProps,
	history: any,
	translate: (text: string) => string,
	user: any,
	isAuthenticated: any,
	bookmarks: any,
	myPermissions: IPermissions
) {
	if (!hasPermission(myPermissions, item.permissionRecord)) {
		return;
	}

	let children: INavItemProps[] = item.children || [];
	const childrenList = children
		.filter((it) => {
			return !it.isNotVisible && (!it.key.includes('reference_data') || bookmarks.includes(it.path));
		})
		.map((child) => {
			return getItem(child, history, translate, user, isAuthenticated, bookmarks, myPermissions);
		}) as MenuProps['items'];

	const principalProperties = {
		key: item.key,
		description: item.description,
		path: item.path,
		icon: renderItem(item, translate, bookmarks),
		disabled: (isAuthenticated && user?.is_access_platform === 0) || item.disabled,
	};
	return item.is_child
		? {
				...principalProperties,
				composed_icon: item.composed_icon,
				is_child: item.is_child ? item.is_child.toString() : null,
				type: item.type,
			}
		: childrenList && childrenList?.length > 0
			? {
					...principalProperties,
					module: item.module,
					children: [addTitle(translate(item.description), `${item.key}-title`, childrenList, 'group')],
				}
			: {
					...principalProperties,
					module: item.module,
				};
}

/**
 * render submenu item
 * @param item
 * @param history
 * @param translate
 * @param user
 * @param isAuthenticated
 * @returns
 */
const renderItem = (item: INavItemProps, translate: (text: string) => string, bookmarks: any) => {
	return (
		<div key={item.key}>
			{
				//child case
				item.is_child ? (
					<Link to={item.path ? item.path : '#'} className="is-child">
						{item.composed_icon ? (
							item.composed_icon
						) : (
							<FontAwesomeIcon icon={item.icon ? item.icon : item.composed_icon} size="lg" />
						)}

						<span className="m-2">{translate(item.description)}</span>
					</Link>
				) : (
					//item case
					<>
						{!item.children ||
						(item.key === 'reference_data' &&
							item.children.filter(
								(it) => it.key.includes('reference_data') && bookmarks.includes(it.path)
							).length === 0) ? (
							<Tooltip title={translate(item.description)} key={item.key} placement={'right'}>
								{renderIcon(item)}
							</Tooltip>
						) : (
							renderIcon(item)
						)}
					</>
				)
			}
		</div>
	);
};

/**
 * render icon
 * @param item
 * @returns
 */
const renderIcon = (item: INavItemProps) => {
	return (
		<Link to={item.path ? item.path : '#'} className="is-parent">
			{item.key === 'ingest-workflows' ? (
				<div className="fa-layers fa-fw">
					<FontAwesomeIcon icon={faSquare} transform="right-3" size="lg" />
					<FontAwesomeIcon icon={faEllipsisHAlt} transform="shrink-1 left-3" size="lg" />
				</div>
			) : item.key === 'coming-soon' ? (
				<FontAwesomeIcon icon={item.icon} size="lg" />
			) : (
				<FontAwesomeIcon icon={item.icon} size="lg" />
			)}
		</Link>
	);
};

/**
 * Check if user has permission to access this item
 * @returns boolean
 */
export const hasPermission = (myPermissions: IPermissions, records: string[]) => {
	return _.some(records, (item) => _.get(myPermissions, [item, 'can_read'], false));
};
