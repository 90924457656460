import React, { Component } from 'react';

import configRecords from 'configRecords';
import ReferenceField from '../../ReferenceField/ReferenceField';

export default class referenceListRenderer extends Component {
	render() {
		if (!this.props.data) {
			return this.props.value || null;
		}

		const colName = this.props.colDef.field;

		const list = this.props.data[colName];

		if (!Array.isArray(list)) return null;

		if (list.length === 0) return null;

		const currConfig = configRecords[colName] || configRecords[this.props.colDef.referenceType] || null;
		if (!currConfig) return '_';
		const path = currConfig.path;
		return (
			<div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
				{list.map((reference, i) => {
					const field = currConfig.navigateByCode ? reference?.code : reference?.id;
					return <ReferenceField key={`reference${i}`} value={reference.name} url={`${path}/${field}`} />;
				})}
			</div>
		);
	}
}
