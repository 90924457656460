import React, { Component } from 'react';

import './NotificationDetails.sass';

export default class NotificationDetails extends Component {
	render() {
		// console.log(this.props);
		return (
			<div className="NotificationDetails">
				<h1 className="mb-0">{this.props.title}</h1>
				{this.props.children ? <p className="mt-2 mb-0">{this.props.children}</p> : null}
			</div>
		);
	}
}
