export default {
	title: 'Data query builder',
	subtitle: 'Create your own query to retrieve records from existing datasets',
	help: 'Filter and extract data',
	messages: {
		query_in_progress_title: 'Query in progress',
		query_in_progress: 'Your query is being executed, please wait...',
		query_ended_successfully_without_result_title: 'No record found',
		query_ended_successfully_without_result:
			'Your query executed successfully but returned no record. Please review your settings',
		query_ended_successfully_with_result_title: 'Query successfully executed',
		query_ended_successfully_with_result: 'Your query has been successfully executed',
	},
	errors: {
		query_error_title: 'Query error',
		query_error: 'Error while trying to query datasets, please check your settings',
		query_error_internal: 'Error while trying to query datasets, please check the issue with DNext team',
	},
};
