export default {
	invalids: {
		description: 'The entries below do not match the rules established for the naming conditions',
	},
	concern: 'Related datasets',
	previous: 'Previous',
	related_dataset: 'dataset that use this reference',
	select_entries: 'Please select all the datasets and columns where you would like to apply the replacement.',
	sure_to_replace: 'Are you sure you want to apply the replacement ? The data will be moved permanently.',
	sure_to_delete: 'Are you sure you want to delete this reference ?',
	please_write_down: ' Please write down',
	to_proceed: ' to proceed',
	delete_reference: 'Replacement is now over, you can delete reference by clicking on the following button',
	action: {
		replace: 'Replace',
		delete: 'Delete',
		check: 'Check',
	},
};
