export default {
	tabs: {
		summary: 'Summary',
		related_records: 'Related records',
		related_datasets: 'Related datasets',
	},
	actions: {
		save: 'Save',
		apply: 'Apply',
		search: 'Search',
		edit: 'Edit',
		view: 'View',
		delete: 'Delete',
		run: 'Run',
		add: 'Add',
		discard: 'Discard',
	},
	messages: {
		attention: 'Attention',
		warning: 'Warning',
		error: 'Error',
		info: 'Information',
	},
	status: 'Status',
	active: 'Active',
	inactive: 'Inactive',
	name: 'Name',
	code: 'Code',
	description: 'Description',
	parent: 'Parent',
	hq_city: 'HQ City',
	hq_country: 'HQ Country',
	notes: 'Notes',
	type: 'Type',
	website: 'Website',
	category: 'Category',
	temporal: 'Temporal',
	contains_temporal: 'Contains temporal',
	relative: 'Relative',
	created: 'Created',
	updated: 'Updated',
	created_at: 'Created at',
	creation_date: 'Creation date',
	updated_at: 'Updated at',
	created_by: 'Created by',
	updated_by: 'Updated by',
	general: 'General',
	coverage: 'Coverage',
	temporal_coverage: 'Temporal Coverage',
	geographical_coverage: 'Geographical coverage',
	publishing: 'Copyright',
	relations: 'Relations',
	data_last_updated: 'Data Last updated',
	metadata_last_updated: 'Metadata Last updated',
	dnext_copyright:
		'This Content has been collected, aggregated and stored by DNEXT. This Content is to be used in the frame of your DNEXT subscription for an internal use. Sharing this Content outside your organization is expressly prohibited. If you use this Content outside DNEXT platform (internal documents, presentations), the source (i.e. Data Owner) should be quoted.',
	contact_support: 'Contact',
	contact_support_email: 'support@dnext.io',
};
