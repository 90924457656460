export enum ImageFileType {
	'SHELL' = '/svg/file_type_sh.png',
	'JSON' = '/svg/file_type_json.png',
	'EML' = '/svg/file_type_eml.png',
	'PBIX' = '/svg/file_type_pbix.png',
	'PDF' = '/svg/file_type_pdf.svg',
	'PNG' = '/svg/file_type_png.png',
	'JPG' = '/svg/file_type_jpg.png',
	'JPEG' = '/svg/file_type_jpeg.png',
	'CSV' = '/svg/file_type_csv.svg',
	'XLS_XLSX' = '/svg/file_type_excel.svg',
	'PPT_PPTX' = '/svg/file_type_ppt.png',
	'DOC_DOCX' = '/svg/file_type_doc.png',
	'FOLDER' = '/svg/folder.svg',
}
