export const validateUUID = (uuid) =>
	/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuid);

export const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

export const snake_case = (s) => {
	return s
		.split(/(?=[A-Z])/)
		.join('_')
		.toLowerCase();
};

export const isAnEntity = (s) =>
	!['text', 'numeric', 'numeric-dot', 'numeric-comma', 'static', 'null', 'date', 'datetime'].includes(s);

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const convertCsvToJson = (csv) => {
	const lines = csv.split('\n');
	const result = [];
	const headers = lines[0].split(',');

	for (let i = 1; i < lines.length; i++) {
		if (!lines[i]) continue;
		const obj = {};
		const currentLine = lines[i].split(',');

		for (let j = 0; j < headers.length; j++) {
			// Remove backslashes from keys and values
			const key = headers[j].replace(/"/g, '');
			const value = currentLine[j].replace(/"/g, '');

			obj[key] = value;
		}
		result.push(obj);
	}
	return result;
};
