import React, { Component } from 'react';

export default class phoneRenderer extends Component {
	render() {
		if (this.props.value == null) return null;

		return (
			<span>
				<a href={`tel:${this.props.value}`}>{this.props.value}</a>
			</span>
		);
	}
}
