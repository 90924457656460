import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

export default class CustomDateComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			date: null,
			placeholder: null,
		};
	}

	render() {
		return (
			<div role="presentation" className="ag-input-wrapper custom-date-filter">
				<DatePicker
					format="DD-MM-YYYY"
					size="small"
					dropdownClassName="ag-custom-component-popup"
					//startof('day') resets the time part of the date to midnight
					//so ag-Grid will be based only on the date part and not the time
					value={this.state.date ? moment(this.state.date).startOf('day') : null}
					onChange={this.onDateChanged.bind(this)}
					placeholder={this.placeholder}
				/>
			</div>
		);
	}

	componentDidMount() {}

	//* ********************************************************************************
	//          METHODS REQUIRED BY AG-GRID
	//* ********************************************************************************

	getDate() {
		// ag-grid will call us here when in need to check what the current date value is hold by this
		// component.
		// Ensure date returned is set to the start of the day to normalize time
		return this.state.date ? moment(this.state.date).startOf('day').toDate() : null;
	}

	setDate(date) {
		// ag-grid will call us here when it needs this component to update the date that it holds.
		// Normalize date to start of the day when setting it
		this.setState({ date: date ? moment(date).startOf('day').toDate() : null });
	}

	//* ********************************************************************************
	//          AG-GRID OPTIONAL METHODS
	//* ********************************************************************************

	setInputPlaceholder(placeholder) {
		this.setState({ placeholder });
	}

	//* ********************************************************************************
	//          LINKS THE INTERNAL STATE AND AG-GRID
	//* ********************************************************************************

	updateAndNotifyAgGrid(date) {
		this.setState(
			{
				date: date ? moment(date).startOf('day').toDate() : null,
			},
			// Callback after the state is set. This is where we tell ag-grid that the date has changed so
			// it will proceed with the filtering and we can then expect ag-Grid to call us back to getDate
			this.props.onDateChanged
		);
	}

	//* ********************************************************************************
	//          LINKING THE UI, THE STATE AND AG-GRID
	//* ********************************************************************************

	onDateChanged = (date, dateString) => {
		this.updateAndNotifyAgGrid(date ? date.toDate() : null);
	};
}
