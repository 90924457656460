export default {
	title: 'Sharing',
	labels: {
		compliance_configuration: 'Compliance Configuration',
		source_copyright: 'Source Copyright',
		contracts: 'Contracts',
		resource_group: 'Resource Group',
		disabled: 'Disabled',
		enabled: 'Enabled',
	},
	messages: {
		contracts_description_first_part: 'List of contractees with contracts related to this',
		contracts_description_second_part: ', grouped by contract status',
		resource_group_description_first_part: 'List of resource groups including this ',
		resource_group_description_second_part: ', grouped by resource groups Status',
		compliance_configuration_first_part: 'This represents the current compliance configuration for this',
		compliance_configuration_second_part: ', to change compliance settings go to edit compliance page',
		source_copyright: 'Internal copyright provided by Dnext and the source',
		source: 'No Source selected yet...',
		data_owner: 'No Data Owner selected yet...',
		data_channel: 'No Data Channel selected yet...',
		copyright: 'No Copyright written yet...',
		generic_source: 'No Generic Source selected yet...',
		generic_copyright: 'No Generic Copyright written yet...',
	},
	tooltips: {
		source: 'Who produces the dataset',
		data_owner: 'Who legally owns the dataset',
		data_channel: 'Who provides the dataset',
	},
	actions: {
		retrieve_sharing_usage: 'Retrieve sharing usage details',
	},
	errors: {
		error_retrieve_sahring_usage: 'An error occurred when retrieving sharing usage details',
	},
};
