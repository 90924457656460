export default {
	name: 'Tag',
	name_plural: 'Tags',
	list: 'Tags', // on a ajouté ça pour ne change pas le name plural en général
	new_record_title: 'New tag',
	tags_to_filter_with: 'Tags to filter with',
	actions: {
		create_new: 'Create a new tag',
		edit: 'Edit tag',
		save: 'Save',
	},
	messages: {
		tag_name:
			'Tag Name is min 3 characters, max 45 characters and alphanumeric characters (letters A-Z, numbers 0-9)',
	},
	tabs: {
		first: {
			title: 'Summary',
			sections: {
				general: 'General',
				template_fields: 'Template fields',
				tags: 'tags',
			},
			name: 'Name',
			keywords: 'Keywords',
			parent: 'Parent',
			created: 'Created',
			updated: 'Updated',
			type: 'Type',
			filter_value: 'Filter value',
			filter_type: 'Filter type',
			mandatory: 'Mandatory',
		},
		second: {
			title: 'Related records',
			sections: {
				datasets: 'Datasets',
			},
			name: 'Name',
		},
	},
};
