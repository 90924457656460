import { faShip } from '@fortawesome/pro-light-svg-icons';
import { basePath, server } from 'src/utils/api-prefix.helper';
import { getTradeflowService } from 'ui/modules/Fundamentals/Tradeflows/services/tradeflow.service';
// constants
const baseApiUrl = `${server}${basePath}/`;
export default {
	referenceData: false,
	iconName: faShip,
	url: `${baseApiUrl}trade-flow`,
	getService: getTradeflowService,
	recordid: 'trade_flow_id',
	urlParams: 'relation=[trade_flow_snapshot,trade_flow_mode]',
	path: '/fundamentals/tradeflows',
	parentPage: {
		name: 'Fundamentals',
	},
	pageTitle: {
		list: 'Tradeflows',
		action: {
			create: 'New trade flow mode',
			edit: 'Edit trade flow mode',
		},
	},
	formatDataFromList: (data: any) =>
		data.map((d: any) => {
			// get last trade flow snapshot created at
			let lastSnapShotCreatedAt = null;
			if (d.trade_flow_snapshot) {
				const snapshotList = [...d.trade_flow_snapshot];
				lastSnapShotCreatedAt = snapshotList.sort((a, b) =>
					a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
				)[0];
			}

			return {
				...d,
				lastSnapShotCreatedAt: d.last_refreshed_date
					? d.last_refreshed_date
					: lastSnapShotCreatedAt
						? lastSnapShotCreatedAt.createdAt
						: null,
			};
		}),
	listButtons: ['canRefreshAndView', 'canbeDuplicate', 'canbeSettings', 'canDownload', 'canRefreshAndDownload'], // ,
	canCreate: true,
	canEdit: true,
	canDelete: true,
	canArchive: false,
	canbeCloned: false,
	canbeDuplicate: true,
	canbeSettings: true,
	canAccessForecasts: true,
	canRefreshAndView: true,
	canDownload: true,
	canRefreshAndDownload: true,
	hasShared: true,
	askCode: true,
	canChangeTags: true,
	navigateByCode: true,
};
