export default {
	name: 'List',
	name_plural: 'Lists',
	search: 'Search...',
	refresh_list: 'Refresh list',
	clean_filters: 'Clear filters',
	search_by: 'Search by ',
	rows_per_page: 'Rows per page',
	export_excel: 'Export to Excel',
	select_columns: 'Select columns',
};
