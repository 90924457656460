export default {
	name: 'Approval',
	name_plural: 'approvals',
	new_record_title: 'New approval',
	actions: {
		create_new: 'Create a new approval',
	},
	tabs: {
		list: 'List',
		approval: 'Pending changes',
		shared_with_us: 'Shared with us',
		active_sharing: 'Shared to others',
	},
};
