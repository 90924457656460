import { faAnchor } from '@fortawesome/pro-light-svg-icons';
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';

// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	pageTitle: {
		list: 'Voyages',
		action: {
			edit: 'Edit voyage',
		},
	},
	path: '/freight/voyages',
	description: 'Freight',
	url: `${serverUrl}freight/voyageInfos`,
	noParams: true,
	urlParams: '',
	iconName: faAnchor,
	columns: [
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Commodity',
			field: 'product_code',
		},
		{
			headerName: 'Vessel',
			field: 'vessel_type_name',
		},
		{
			headerName: 'Load Port',
			field: 'load_port',
		},
		{
			headerName: 'Top Off Port',
			field: 'top_off_port',
		},
		{
			headerName: 'Discharge Port',
			field: 'discharge_port',
		},
		{
			headerName: 'Routing port',
			field: 'routing_port',
		},
		{
			headerName: 'Origin port',
			field: 'origin_port',
		},
	],
	canView: false,
	canCreate: false,
	canEdit: false,
	canDelete: false,
};
