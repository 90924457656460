export default {
	name: 'Supply & demand',
	name_plural: 'Supply & demand',
	title: 'Supply & demand',
	new_record_title: 'New supply & demand',
	actions: {
		create_new: 'Create a supply & demand',
		back: 'Back to supply & demand priority rules',
		back_form: 'Back to supply & demand parameters',
		view: 'View',
	},
	settings: {
		title: 'Supply & demand settings',
	},
	modals: {
		add_dataset: {
			title: 'Add dataset',
		},
	},
};
